import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useEffect } from "react";
import { useContext } from "react";
import STcontext from "../../../context/STcontext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const ShowAppareilPerUserST = () => {
  const {
    getAppareilsPerUser,
    apparielsPerUser,
    setAppareilPerUser,
    setShowAppareilPerUser,
    handleAffectedArticleOption,
    technicien,
    editAppareilsPreUser,
    handleChangeEditAppareil,
  } = useContext(STcontext);

  const { id, typeAppareil, query } = useParams();
  
  const [IdType, setIdType] = useState();
  const [selectedAppareils, setSelectedAppareils] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [appareilPreEtat, setAppareilPreEtat] = useState([]);

  const [rowClick, setRowClick] = useState(true);

  useEffect(() => {
    setAppareilPerUser(null);
    let typeId;
    switch (typeAppareil) {
      case "GPS":
        typeId = 1;
        setIdType(1);
        break;
      case "SIM":
        typeId = 2;
        setIdType(2);
        break;
      case "accessorie":
        typeId = 3;
        setIdType(3);
    }
    getAppareilsPerUser(id, typeId);
  }, []);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    serial_number: { value: null },
    nameModele: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    nameType: { value: null, matchMode: FilterMatchMode.IN },
    numberBox: { value: null, matchMode: FilterMatchMode.IN },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    filterCahnge(value)
  };
  
  const filterCahnge = (value)=>{
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
  }
  
  useEffect(()=>{
    if(query !=""){
      setGlobalFilterValue(query)
      filterCahnge(query)
    }
  }, [query])

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  
  useEffect(() => {
    if (selectedAppareils != null) {
      const appareilsId = selectedAppareils.map(
        (appareil) => appareil.appareilId
      );
      handleAffectedArticleOption(appareilsId);
    }
  }, [selectedAppareils]);
  useEffect(() => {
    setSelectedAppareils(null);
    setAppareilPreEtat(
      apparielsPerUser?.filter((filterAppareil) => filterAppareil.etat == 0)
    );
  }, [apparielsPerUser]);
  const header = renderHeader();

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const dateBodyFormat = (rowDate) => {
    return formatDate(rowDate.created_at);
  };

  //form validation for button

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      editAppareilsPreUser(event, id, IdType);
    }
    setValidated(true);
  };

  const handleFilterAppareilPreEtat = async (e) => {
    const etat = await e.target.value;
    setAppareilPreEtat(
      apparielsPerUser.filter((filterAppareil) => filterAppareil.etat == etat)
    );
  };

  return (
      <div style={{
          backgroundColor: "white",
          width: "100%",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "2rem",
        }}>
        <div

          style={{
            backgroundColor: "white",
            width: "95%",
            padding: "2rem",
            marginLeft: "20px",
            marginTop: "1rem",
            borderRadius: "2rem",
          }}
        >
          <div
            className="showAppareilsPreUser"
            style={{ marginBottom: "1rem" }}
          >
            <h5>
              List Des Appareils&nbsp;{" "}
              {apparielsPerUser?.map((userName) => userName.name)[0]}
            </h5>
            {/* filter GPS by etat [not installed , installed*/}
            <Form>
              <Row md="2">
                <Form.Group
                  className="mb-2 mx-2"
                  as={Col}
                  md="4"
                  controlId="validationCustom01"
                >
                  <Form.Label>select les appareils par: </Form.Label>
                  <Form.Select
                    required
                    type="text"
                    name="type"
                    onChange={handleFilterAppareilPreEtat}
                  >
                    <option disabled>select les appareils par</option>
                    <option value="0" selected>
                      Nouveaux Appareils
                    </option>
                    <option value="2">Désinstallées</option>
                  </Form.Select>
                </Form.Group>
              </Row>
            </Form>
          </div>
          <DataTable
            value={appareilPreEtat}
            selectionMode={rowClick ? null : "checkbox"}
            selection={selectedAppareils}
            onSelectionChange={(e) => setSelectedAppareils(e.value)}
            dataKey="appareilId"
            header={header}
            paginator
            sortMode="multiple"
            filters={filters}
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            globalFilterFields={[
              "serial_number",
              "nameModele",
              "nameType",
              "numberBox",
            ]}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              header="#"
              headerStyle={{ width: "3rem" }}
              body={(data, options) => options.rowIndex + 1}
              bodyStyle={{ fontWeight: "bold" }}
            ></Column>
            <Column
              selectionMode="multiple"
              headerStyle={{
                width: "3rem",
              }}
              bodyStyle={{
                display: "flex",
                justifyContent: "start",
                padding: "13px",
              }}
            ></Column>
            <Column
              header="IMEI"
              field="serial_number"
              style={{ width: "20%" }}
            ></Column>
            {IdType === 1 ? (
              <Column
                header="Number SIM"
                field="SIM"
                style={{ width: "20%" }}
              ></Column>
            ) : (
              ""
            )}

            <Column
              header="Model"
              field="nameModele"
              style={{ width: "20%" }}
            ></Column>
            <Column
              header="Date"
              field="nameType"
              body={dateBodyFormat}
              style={{ width: "20%" }}
            ></Column>
          </DataTable>

          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                width: "80%",
                gap: "20px",
                padding: "2rem",
              }}
            >
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Select
                  as="select"
                  aria-label="Default select example"
                  name="technicien"
                  onChange={handleChangeEditAppareil}
                  required
                  isInvalid={validated && !selectedOption}
                >
                  <option value="">Veuillez choisir un technicien</option>
                  {technicien.map((tech) => (
                    <option data-special="tech" key={tech.id} value={tech.id}>
                      {tech.name}
                    </option>
                  ))}
                  <option data-special="ST">Support Technique</option>
                </Form.Select>
              </Form.Group>
              <Button type="submit" variant="primary">
                Envoyer
              </Button>
            </div>
          </Form>
        </div>
      </div>
  );
};
