import React from "react";
import "./style/dashboard.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { NavLink } from "react-router-dom";

let tasks = [];

export default function Cards({ data }) {
  tasks.push(data);
  return (
    <MDBRow className="cardRow">
      <MDBCol>
        <NavLink
          to="/tâches/Tâches-total"
          state={{
            idTask: data ? data : "",
          }}
        >
          <MDBCard className="cardStats">
            <MDBCardBody>
              <MDBCardTitle>Tâches total</MDBCardTitle>
              <MDBCardText>
                {data ? data.map((tache) => tache.id).length : "en cours"}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </NavLink>
      </MDBCol>
      <MDBCol>
        <NavLink
          to="/tâches/réalisée"
          state={{
            idTask: data ? data.filter((tache) => tache.etat_tache == 2) : "",
          }}
        >
          <MDBCard className="cardStats">
            <MDBCardBody>
              <MDBCardTitle>Tâches réalisée</MDBCardTitle>
              <MDBCardText>
                {data
                  ? data.filter((tache) => tache.etat_tache == 2).length
                  : "en cours"}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </NavLink>
      </MDBCol>
      <MDBCol>
        <NavLink
          to="/tâches/reportée"
          state={{
            idTask: data ? data.filter((tache) => tache.etat_tache == 3) : "",
          }}
        >
          <MDBCard className="cardStats">
            <MDBCardBody>
              <MDBCardTitle>Tâches reportée</MDBCardTitle>
              <MDBCardText>
                {data
                  ? data.filter((tache) => tache.etat_tache == 3).length
                  : "en cours"}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </NavLink>
      </MDBCol>
      <MDBCol>
        <NavLink
          to="/tâches/annulée"
          state={{
            idTask: data ? data.filter((tache) => tache.etat_tache == 4) : "",
          }}
        >
          <MDBCard className="cardStats">
            <MDBCardBody>
              <MDBCardTitle>Tâches annulée</MDBCardTitle>
              <MDBCardText>
                {data
                  ? data.filter((tache) => tache.etat_tache == 4).length
                  : "en cours"}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </NavLink>
      </MDBCol>
    </MDBRow>
  );
}
