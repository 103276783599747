import React, { Fragment, useContext, useRef } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "../../context/axios";
import STcontext from "../../context/STcontext";
import { Checkbox } from "primereact/checkbox";
import "./style/moreDetails.css";
export default function DetailsModel({ taskToUpdate }) {
  const { NewTask, supportTechiqueTechnicianTask, modeles,searchnInputTEchnicianPerDate,handleSearchTechnicianTaskPerDateClicked,allGps,setAllGps,allSim,setAllSim } =
    useContext(STcontext);
  const [newDataTask, setNewDataTask] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setNewDataTask("");
    setShow(false)
  };
  const values = [true];
  const [TaskToUpdateFromST, setTaskToUpdateFromST] = useState({
    garantie: taskToUpdate.garantie,
  });
  const [fullscreen, setFullscreen] = useState(true);
  const [images, setImages] = useState({
    imageUrl: "",
    id: 0,
  });

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
    setTaskToUpdateFromST(taskToUpdate);
  }

  const handelUpdateTaskFromST = (e) => {
    const { name, value } = e.target;
    setTaskToUpdateFromST({ ...TaskToUpdateFromST, [name]: value });
    setNewDataTask({ ...newDataTask, [name]: value });
  };
  const handleCheckBoxGrantie = (e) => {
    let garantieCheckBox = "";
    if (TaskToUpdateFromST.garantie == false) {
      garantieCheckBox = true;
    } else {
      garantieCheckBox = false;
    }
    setTaskToUpdateFromST({
      ...TaskToUpdateFromST,
      garantie: garantieCheckBox,
    });
    setNewDataTask({ ...newDataTask, garantie: garantieCheckBox });
  };

  const removeInstalledDevice=()=>{
    setAllGps(allGps.filter(device => device.serial_number !==newDataTask.IMEI));
    setAllSim(allSim.filter(sim => sim.serial_number !==newDataTask.SIM));
  }

  const updateTaskSubmit = async (e, id) => {
    e.preventDefault();
    try {
      await axios.put(`api/supportTechnique/updateTask/${id}`, newDataTask);
      if(searchnInputTEchnicianPerDate.startDate !="" && searchnInputTEchnicianPerDate.endDate !=""){
        handleSearchTechnicianTaskPerDateClicked(1);
      }
      else{
        supportTechiqueTechnicianTask();
      }     
      removeInstalledDevice();
      handleClose();
      setNewDataTask("");
    } catch (err) {
      console.error(err);
    }
  };
  

  const showImage = (imageUrl, id) => {
    setImages({ imageUrl, id });
  };
  const closeImageShow = () => {
    setImages({ imageUrl: "", id: 0 });
  };

  const [retation, setRetation] = useState(0);
  const getImage = useRef();

  const retateImage = () => {
    setRetation(retation + 90);
  };

  let gpsPosition = "";
  switch(TaskToUpdateFromST.gps_principale){
    case 0:
      gpsPosition = "Première";
      break;
    case 1:
      gpsPosition = "Deuxième";
      break;
    default:
      gpsPosition = "GPS Position";
  }

return (
    <div>
      <Button
        style={{ background: "none", color: "black", border: "none" }}
        onClick={() => handleShow(values)}
      >
        <AddCircleIcon />
      </Button>
      <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
        {images.imageUrl ? (
          <div className="show-image">
            <div className="close-retation-buttons">
              <div
                className="close-retation-button-show-image"
                aria-label="Close"
                onClick={retateImage}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z" />
                </svg>
              </div>
              <div
                className="close-retation-button-show-image"
                aria-label="Close"
                onClick={closeImageShow}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 98">
                  <path d="M78.2 23.3L75 20.1 48.7 46.4 22.5 20.1l-3.3 3.2 26.3 26.3-27.2 27.2 3.2 3.3 27.2-27.3L76 80.1l3.2-3.3L52 49.6l26.2-26.3z"></path>
                </svg>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {" "}
              <img
                ref={getImage}
                style={{
                  maxWidth: "50%",
                  width: "auto",
                  transform: `rotate(${retation}deg)`,
                }}
                className="d-block"
                src={"https://d3instal.com/images/" + images.imageUrl}
                alt="..."
              />
            </div>
            <div></div>
          </div>
        ) : (
          ""
        )}

        <Modal.Header closeButton>
          <Modal.Title>Détails tâche</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ gap: "20px", marginBottom: "10px", overflowY: "scroll" }}
        >
          <Form
            noValidate
            method="POST"
            onSubmit={(e) => updateTaskSubmit(e, TaskToUpdateFromST.id)}
          >
            <Row className="mb-3">
              {/* NameClient */}
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Nom client: </Form.Label>
                <Form.Control
                  required
                  list="listClient"
                  type="text"
                  name="client_name"
                  autoComplete="off"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.client_name}
                />
                <datalist id="listClient">
                  {NewTask.Clients.map((client) => (
                    <option key={client.id}>{client.nomComplet}</option>
                  ))}
                </datalist>
              </Form.Group>

              {/* matricule */}
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Matricule: </Form.Label>
                <Form.Control
                  type="text"
                  name="matricule"
                  autoComplete="off"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.matricule}
                />
              </Form.Group>

              {/* type tache */}
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Type du tâche : </Form.Label>
                <Form.Control
                  list="typeTasks"
                  type="text"
                  name="catache"
                  autoComplete="off"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.catache}
                />
                <datalist id="typeTasks">
                  {NewTask.TypesTasks.map((typeTask) => (
                    <option key={typeTask.id}>{typeTask.catache}</option>
                  ))}
                </datalist>
              </Form.Group>

              {/* IMEI */}
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>IMEI: </Form.Label>
                <Form.Control
                  required
                  list="imeiList"
                  type="text"
                  name="IMEI"
                  autoComplete="off"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.IMEI}
                />
                <datalist id="imeiList">
                  {allGps?allGps.map((imei) => (
                    <option key={imei.id}>{imei.serial_number}</option>
                  )):""}
                </datalist>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              {/* model GPS */}
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Modele Gps: </Form.Label>
                <Form.Control
                  required
                  list="modeleGps"
                  autoComplete="off"
                  type="text"
                  name="nameModeleGPS"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.nameModeleGPS}
                />
                <datalist id="modeleGps">
                  {modeles.filter((modelName)=>modelName.type_id != 4 && modelName.type_id !=5 && modelName.type_id !=8 ).map((modelName) => (
                    <option key={modelName.id}>{modelName.nameModele}</option>
                  ))}
                </datalist>
              </Form.Group>

              {/* propreitaire GPS */}
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Prop GPS: </Form.Label>
                <Form.Select
                  list="proprietaireList"
                  required
                  type="text"
                  name="proprietaire"
                  onChange={handelUpdateTaskFromST}
                >
                  <option value="none" selected disabled hidden>
                    {TaskToUpdateFromST.proprietaire === 0
                      ? "D3 hi-tech"
                      : TaskToUpdateFromST.proprietaire === 1
                      ? "client"
                      : "proprietaire GPS"}
                  </option>
                  <option disabled>proprietaire</option>
                  <option value="DHT">D3-hi-tech</option>
                  <option value="client">Client</option>
                </Form.Select>
              </Form.Group>

              {/* GPS Position */}
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>GPS Position: </Form.Label>
                <Form.Select
                  type="text"
                  placeholder=""
                  name="GPSPosition"
                  onChange={handelUpdateTaskFromST}
                >
                  <option value="none" selected disabled hidden>
                    {gpsPosition}
                  </option>
                  <option disabled>GPS Position</option>
                  <option value="principale">Première</option>
                  <option value="secondaire">Deuxième</option>
                </Form.Select>
              </Form.Group>

              {/* model SIM */}
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Modele SIM: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  list="modeleSim"
                  autoComplete="off"
                  name="nameModeleSIM"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.nameModeleSIM}
                />
                <datalist id="modeleSim">
                  {modeles.filter((modelName) => modelName.type_id ==4 || modelName.type_id ==5).map((modelName)=>(
                    <option key={modelName.id} value={modelName.nameModele}>{modelName.nameModele}</option>
                  ))}
                </datalist>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              {/* sim number */}
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>SIM : </Form.Label>
                <Form.Control
                  list="sim_numbers"
                  required
                  autoComplete="off"
                  type="text"
                  name="SIM"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.SIM}
                />
                <datalist id="sim_numbers">
                  {allSim?allSim.map((imei,index) => (
                    <option key={index} value={imei.serial_number}>{imei.latest_device_relating.length > 0
                      ? imei.latest_device_relating.map(
                          (deviceRelating) => deviceRelating.serial_number
                        )[0]
                      : ""}</option>
                  )):""}
                </datalist>
              </Form.Group>

              {/* propreitaire sim */}
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Prop SIM: </Form.Label>
                <Form.Select
                  type="text"
                  placeholder=""
                  name="SIM_proprietaire"
                  onChange={handelUpdateTaskFromST}
                >
                  <option value="none" selected disabled hidden>
                    {TaskToUpdateFromST.SIM_proprietaire === 0
                      ? "D3 hi-tech"
                      : TaskToUpdateFromST.SIM_proprietaire === 1
                      ? "client"
                      : "Sim Propreitaire"}
                  </option>
                  <option disabled>Sim Propreitaire</option>
                  <option value="DHT">D3-hi-tech</option>
                  <option value="client">Client</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Type Relais: </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="accesoriesModel"
                  onChange={handelUpdateTaskFromST}
                >
                  <option value={
                    TaskToUpdateFromST.vehicules &&
                    TaskToUpdateFromST.vehicules.articles.length > 0
                      ? TaskToUpdateFromST.vehicules.articles.map(
                          (modelRelay) =>
                            modelRelay.modele_id == 21
                              ? "Spécial"
                              : modelRelay.modele_id == 22
                              ? "normal"
                              : "sélectionnez relais"
                        )
                      : "sélectionnez relais"
                  }>{
                    TaskToUpdateFromST.vehicules &&
                    TaskToUpdateFromST.vehicules.articles
                      ? TaskToUpdateFromST.vehicules.articles.map(
                          (modelRelay) =>
                            modelRelay.modele_id == 21
                              ? "Spécial"
                              : modelRelay.modele_id == 22
                              ? "normal"
                              : "sélectionnez relais"
                        )
                      : "sélectionnez relais"
                  }</option>
                  {modeles.filter((modelName)=>modelName.type_id ==8 ).map((modelName) => (
                    <option key={modelName.id}>{modelName.nameModele}</option>
                  ))}
               
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Garantie: </Form.Label>
                <Checkbox
                  name="garantie"
                  className="ms-3 mb-2 mt-4"
                  onChange={handleCheckBoxGrantie}
                  checked={
                    TaskToUpdateFromST && TaskToUpdateFromST.garantie == 0
                      ? true
                      : false
                  }
                ></Checkbox>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Exécutée par: </Form.Label>
                <Form.Control
                  required
                  list="userList"
                  type="text"
                  name="user_name"
                  autoComplete="off"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.user_name}
                />
                <datalist id="userList">
                  {NewTask.Users.filter((user) => user.profil_id === 1).map(
                    (user) => (
                      <option key={user.id}>{user.name}</option>
                    )
                  )}
                </datalist>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Réalisée à : </Form.Label>
                <Form.Select
                  required
                  type="text"
                  name="cityClient"
                  autoComplete="off"
                  onChange={handelUpdateTaskFromST}
                >
                  <option selected hidden>
                    {TaskToUpdateFromST.cityName || "select ville"}
                  </option>
                  <option disabled>select Ville</option>
                  {NewTask.Cities.map((city, index) => (
                    <option key={index} value={city.id}>
                      {city.cityName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Date de travail : </Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="date_previsionnelle_debut"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.date_previsionnelle_debut}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="validationCustom02">
                <Form.Label>Date fin realisation : </Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="date_fin_realisation"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.date_fin_realisation}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="3" controlId="validationCustom01">
                <Form.Label>Observation : </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  name="observation"
                  onChange={handelUpdateTaskFromST}
                  value={TaskToUpdateFromST.observation}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 imageSlider" md="2">
              {TaskToUpdateFromST.images
                ? TaskToUpdateFromST.images.map((imagesTask) => (
                    <div
                      className="images"
                      style={{ maxWidth: "15%" }}
                      key={imagesTask.id}
                    >
                      <img
                        className="w-100 d-block"
                        // itemId={imagesTask.id}
                        src={
                          "https://d3instal.com/images/" +
                          imagesTask.url_imgtache
                        }
                        alt="..."
                        onClick={() =>
                          showImage(imagesTask.url_imgtache, imagesTask.id)
                        }
                        style={{ aspectRatio: "3/3" }}
                      />
                    </div>
                  ))
                : ""}
            </Row>
            <Row className="mb-5 mt-5">
              <Col className="d-flex align-items-center justify-content-center">
                <Button type="submit" variant="primary">
                  Enregistrer les modifications
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
