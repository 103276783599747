import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import STcontext from "../../../context/STcontext";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const Gps = ({ state }) => {
  const navigateTo = useNavigate();
  const { getGerantAppareil, gerantAppareil } = useContext(STcontext);
  const [pages, setPages] = useState(1);
  const [rowsGpsPrePage, setGpsPrePage] = useState(5);
  const lastGpsIndex = pages * rowsGpsPrePage;
  const firstGpsIndex = lastGpsIndex - rowsGpsPrePage;
  const currentGpsPrePage = gerantAppareil?.gpsUserAssociate?.slice(
    firstGpsIndex,
    lastGpsIndex
  );
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [filterDevices, setfilterDevices] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  useEffect(() => {
    getGerantAppareil();
  }, []);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Recherche par mot clé"
          />
        </span>
      </div>
    );
  };

  const formatDateBody = (rowDate) => {
    return formatDate(rowDate.created_at);
  };

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  
  const logDeviceBody = (rowData) => {
    const device = rowData.serial_number;
    return (
      <>
        <Button
          onClick={() =>
            navigateTo(`/Gerant/appareils/GPS/log/${device}`, {
              state: { idDevice: rowData.id },
            })
          }
          variant="info"
        >
          Voir
        </Button>
      </>
    );
  };



  const getStatus = (redStatus) => {
    const status = redStatus.etat;
    const garantie = redStatus.garantie;
    switch (status) {
      case 0:
        switch (garantie) {
          case 0:
            return "Nouveau";
          case 1:
            return "Bon Occasion";
        }
      case 1:
        return "installé";
      case 2:
        return "Desinstallé";
      case 3:
        return "Endommagé";
      case 4:
        return "Supprimé";
    }
  };
  const getSeverity = (redStatus) => {
    const status = redStatus.etat;
    const garantie = redStatus.garantie;
    switch (status) {
      // devices not installed
      case 0:
        switch (garantie) {
          // new DHT DEVICES
          case 0:
            return "success";
          // Good opportunity
          case 1:
            return "warning";
        }
      // installed
      case 1:
        return "info";
      //
      case 2:
        return "renewal";
      case 3:
      case 4:
        return "danger";
    }
  };

  const handelfilterChange=(e)=>{
    setfilterDevices(e.target.value)
  }

  //filter devices by status
  const handleFilteringDevices = () => {
    let etat = "";
    let garantie = "";
    switch (filterDevices) {
      case "new":
        // new DHT device
        etat = 0;
        garantie = 0;
        break;
      case "installed":
        // installed devices
        etat = 1;
        break;
      case "uninstalled":
        // uninstalled devices
        etat = 2;
        break;
      case "good_opportunity":
        // Good opportunity
        etat = 0;
        garantie = 1;
        break;
      case "damaged":
        //Damaged
        etat = 3;
        break;
      case "deleted":
        //Deleted
        etat = 4;
        break;
      default:
        etat = "";
        garantie = "";
    }

    setSelectedDevices(
      gerantAppareil.AllGPS?.filter(
        (device) =>
          (etat === "" || device.etat == etat) &&
          (garantie === "" || device.garantie == garantie)
      )
    );
  };

  useEffect(()=>{
    handleFilteringDevices()
  },[gerantAppareil.AllGPS,filterDevices])

  const statusBodyTemplate = (rowData) => {
    return <Tag value={getStatus(rowData)} severity={getSeverity(rowData)} />;
  };

  // paginaed bottom table(devices pre user)
  let numberPageGpsPreUser = [];
  for (
    let i = 1;
    i <= Math.ceil(gerantAppareil.gpsUserAssociate.length / rowsGpsPrePage);
    i++
  ) {
    numberPageGpsPreUser.push(i);
  }
  const header = renderHeader();
  return (
    <div
      style={{
        width: "90%",
        padding: "2rem",
        marginTop: "1rem",
        marginLeft: "50px",
        borderRadius: "2rem",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "2rem",
        }}
      >
        <div className="bar-table" style={{ padding: "0rem 2rem 1rem 2rem " }}>
          <div>
            <h5>
              GPS &nbsp; <span class="vl"></span> &nbsp;{" "}
              <span style={{ fontSize: "15px" }}>
                {" "}
                {selectedDevices ? selectedDevices.length : ""} Appareils
              </span>{" "}
            </h5>
          </div>
        </div>
        <Row md="2">
          <Form.Group
            className="mb-2 mx-2"
            as={Col}
            md="4"
            controlId="validationCustom01"
          >
            <Form.Label>select les appareils par: </Form.Label>
            <Form.Select
              required
              type="text"
              name="type"
              onChange={handelfilterChange}
            >
              <option disabled>select les appareils par</option>
              <option value="" selected>
                tous les appareils
              </option>
              <option value="new">Nouveaux</option>
              <option value="installed">Installés</option>
              <option value="uninstalled">Desinstallés</option>
              <option value="good_opportunity">Bon Occasion</option>
              <option value="damaged">Endommagé</option>
              <option value="deleted">Supprimé</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <DataTable
          value={selectedDevices}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          dataKey="id"
          filters={filters}
          globalFilterFields={[
            "serial_number",
            "nameModele",
            "nameType",
            "status",
          ]}
          header={header}
          emptyMessage="Aucun appareil trouvé."
        >
          <Column
            header="#"
            headerStyle={{ width: "3rem" }}
            body={(data, options) => options.rowIndex + 1}
            bodyStyle={{ fontWeight: "bold" }}
          ></Column>
          <Column
            header="IMEI"
            field="serial_number"
            style={{ minWidth: "12rem", height: "4rem" }}
          />
          <Column
            header="Modéle"
            field="nameModele"
            showFilterMenu={false}
            style={{ minWidth: "14rem", height: "4rem" }}
          />
          <Column
            field="nameType"
            header="Type"
            filterPlaceholder="Search by name"
            style={{ minWidth: "12rem", height: "4rem" }}
          />
          <Column
            field="etat"
            header="Status"
            sortable
            showFilterMenu={false}
            style={{ minWidth: "12rem", height: "4rem" }}
            body={statusBodyTemplate}
          />
          <Column
            field="created_at"
            sortable
            header="Date D'entrée"
            style={{ minWidth: "6rem", height: "4rem" }}
            body={formatDateBody}
          />
          <Column
            header="Log"
            style={{ minWidth: "6rem", height: "4rem" }}
            body={logDeviceBody}
          />
        </DataTable>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            backgroundColor: "white",
            width: "80%",
            padding: "2rem",
            marginTop: "1rem",
            borderRadius: "2rem",
          }}
        >
          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Utilisateur</th>
                <th scope="col">Quantité</th>
              </tr>
            </MDBTableHead>
            {currentGpsPrePage.map((GPS, index) => (
              <MDBTableBody>
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center"></div>
                  </td>
                  <td>
                    <p className="fw-normal mb-1">{GPS.name}</p>
                  </td>

                  <td>
                    <p className="fw-normal mb-1">{GPS.numberArticle}</p>
                  </td>
                </tr>
              </MDBTableBody>
            ))}
          </MDBTable>
          {gerantAppareil.gpsUserAssociate.length > 0 ? (
            <nav aria-label="...">
              <MDBPagination className="mb-0 d-flex justify-content-end">
                <MDBPaginationItem disabled>
                  <MDBPaginationLink
                    href="#"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    Pages
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {numberPageGpsPreUser.map((numberPage, index) => (
                  <MDBPaginationItem
                    key={index}
                    className={`${numberPage == pages ? "active" : "null"}`}
                  >
                    <MDBPaginationLink
                      style={{ cursor: "pointer" }}
                      onClick={() => setPages(numberPage)}
                    >
                      {numberPage}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </nav>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Gps;
