import React, { useContext, useEffect, useState } from "react";
import "../../../App.css";
import _ from "lodash";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import SIM from "./SGA/Sim";
import Gps from "./SGA/Gps";
import Accessoires from "./SGA/Accessoires";
import STcontext from "../../context/STcontext";
import { useLocation } from "react-router-dom";

export default function AppCards() {
  const located = useLocation();
  const param = located.state?.params;
  const [table, setTable] = useState();
  const [tableSelected, setTableSelected] = useState(param);
  const { getGerantAppareil, gerantAppareil } = useContext(STcontext);

  useEffect(() => {
    getGerantAppareil();
  }, []);
  useEffect(() => {
    handleTable();
  }, [tableSelected]);

  const handleTableChange = (e) => {
    const { id } = e.target;
    setTableSelected(id);
  };

  const handleTable = () => {
    let currenttable;
    switch (tableSelected) {
      case "SIM":
        currenttable = <SIM />;
        break;
      case "ACC":
        currenttable = <Accessoires />;
        break;
      default:
        currenttable = <Gps />;
    }
    setTable(currenttable);
  };

  return (
    <>
      <MDBRow
        className="AppCard"
        style={{ width: "100%", padding: "30px 100px 30px 70px" }}
      >
        <MDBCol>
          <MDBCard className="cardStats">
            <MDBCardBody
              className="pointer"
              id="GPS"
              onClick={(e) => handleTableChange(e)}
            >
              <MDBCardTitle>Gps </MDBCardTitle>
              <span className="card-text">{gerantAppareil.NumberGps}</span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard className="cardStats">
            <MDBCardBody
              className="pointer"
              id="SIM"
              onClick={(e) => handleTableChange(e)}
            >
              <MDBCardTitle>SIM</MDBCardTitle>
              <span className="card-text">{gerantAppareil.NumberSIM}</span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol>
          <MDBCard className="cardStats  ">
            <MDBCardBody
              className="pointer"
              id="ACC"
              onClick={(e) => handleTableChange(e)}
            >
              <MDBCardTitle>Accessoires</MDBCardTitle>
              <span className="card-text">
                {gerantAppareil.NumberAccessory}
              </span>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <div>
        {table == null ? (
          <div
            style={{
              width: "90%",
              padding: "2rem",
              marginTop: "1rem",
              marginLeft: "50px",
              borderRadius: "2rem",
            }}
          ></div>
        ) : (
          table
        )}
      </div>
    </>
  );
}
