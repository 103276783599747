import React, { memo, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import STcontext from "../../../context/STcontext";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import pusher from "../../../../pusher";
import ImportSim from "../modelAppareil/ImportSim";
import { AddNewSim } from "../modelAppareil/AddNewSim";

const SIM = () => {
  const appareilId = 2
  const Navigate = useNavigate();

  const {
    technicien,
    technicianAll,
    SubmittedToTechnician,
    handleAffectedTechnicianOption,
    handleAffectedArticleOption,
    handleGetAllModeles,
    appareil,
    getAppareil,
    errorsMessages,
    setGlobalSearch,
    globalSearch,
    globalSearchRes,
    handleGlobalSearch,
  } = useContext(STcontext);

  useEffect(() => {
    setGlobalSearch({...globalSearch,search:""})
    technicianAll();
    getAppareil();
    handleGetAllModeles();
    const channel = pusher.subscribe("Installer_realTime");
    channel.bind("realTimeData", (data) => {
      getAppareil();
    });
  }, []);

  useEffect(() => {
    // Clean up the Pusher connection when the component unmounts
    return () => {
      pusher.unsubscribe("Installer_realTime");
    };
  }, []);

  const [selectedAppareils, setSelectedAppareils] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [sim, setSim] = useState([]);
  const [selectSimBy, setSelectSimBy] = useState("0");

  // function filter SIM by [new sim, old sim]
  const filterSimBy = (simToFilter) => {
    switch (selectSimBy) {
      case "0":
        setSim(simToFilter.filter((filterSim) => filterSim.etat === 0));
        break;
      case "3":
        setSim(simToFilter.filter((filterSim) => filterSim.etat === 3));
        break;
    }
  };


  useEffect(() => {
    filterSimBy(appareil.AllSIM);
  }, [appareil]);


  useEffect(() => {
    filterSimBy(appareil.AllSIM);
  }, [selectSimBy]);


  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    serial_number: { value: null },
    nameModele: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    nameType: { value: null, matchMode: FilterMatchMode.IN },
    numberBox: { value: null, matchMode: FilterMatchMode.IN },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    if (selectedAppareils != null) {
      const appareilsId = selectedAppareils.map((appareil) => appareil.id);
      handleAffectedArticleOption(appareilsId);
    }
  }, [selectedAppareils]);


  useEffect(() => {
    setSelectedAppareils(null);
  }, [appareil.AllGPS]);

    // table appareil pre user

    const [pages, setPagesAppareilPreUser] = useState(1);
    const [rowsPrePage, setRowsPrePage] = useState(5);
    const lastAppareilIndex = pages * rowsPrePage;
    const firstAppareilIndex = lastAppareilIndex - rowsPrePage;
    const currentAppareilPreUser = appareil.simUserAssociate.slice(
      firstAppareilIndex,
      lastAppareilIndex
    );
  
    let numberPagesAppareilPreUser = [];
    for (
      let p = 1;
      p <= Math.ceil(appareil.simUserAssociate.length / rowsPrePage);
      p++
    ) {
      numberPagesAppareilPreUser.push(p);
    }
  

  const [simPreUser,setSimPreUser]=useState(currentAppareilPreUser)

  const filterAppareilPreUser =()=>{
    if (globalSearchRes !="" && globalSearchRes !=null) {
      setSimPreUser(currentAppareilPreUser.filter((appareilPreUser)=>appareilPreUser.id === globalSearchRes.userId))
    }
    else{
      setSimPreUser(currentAppareilPreUser)
    }
  }
  
  useEffect(()=>{
    filterAppareilPreUser();
  },[globalSearchRes])

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const renderHeaderGlobalSearch = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalSearch.search}
            onChange={(e)=>handleGlobalSearch(e,appareilId)}
            placeholder="recherche globale"
          />
        </span>
      </div>
    );
  };

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const dateBodyFormat = (rowDate) => {
    return formatDate(rowDate.created_at);
  };

  const ownerBody = (rowOwner) => {
    return rowOwner.proprietaire === 1 ? "Client" : "DHT";
  };


  const header = renderHeader();

  return (
    <div
      style={{
        width: "90%",
        padding: "2rem",
        marginTop: "1rem",
        marginLeft: "50px",
        borderRadius: "2rem",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "2rem",
        }}
      >
        <h5>
          SIM &nbsp; <span class="vl"></span> &nbsp;{" "}
          <span style={{ fontSize: "15px" }}> {sim.length} cartes</span>{" "}
        </h5>
        <div className="bar-table" style={{ padding: "0rem 2rem 1rem 0rem " }}>
          <Container>
            <Row>
              <Col xs={12} md={8}>
                <Form.Group
                  as={Col}
                  md="4"
                  className="mb-2 mx-2"
                  controlId="validationCustom04"
                >
                  <Form.Label>select SIM par: </Form.Label>
                  <Form.Select
                    required
                    type="text"
                    name="type"
                    onChange={(e) => setSelectSimBy(e.target.value)}
                  >
                    <option disabled>select les appareils par</option>
                    <option value={0} selected>
                      Nouveaux SIM
                    </option>
                    <option value={3}>Ancien SIM</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col
                xs={6}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <AddNewSim />
                <ImportSim />
              </Col>
            </Row>
          </Container>
        </div>
        <DataTable
          value={sim}
          selectionMode={rowClick ? null : "checkbox"}
          selection={selectedAppareils}
          onSelectionChange={(e) => setSelectedAppareils(e.value)}
          dataKey="id"
          header={header}
          paginator
          sortMode="multiple"
          filters={filters}
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          globalFilterFields={[
            "serial_number",
            "nameModele",
            "nameType",
            "numberBox",
            "proprietaire",
          ]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            header="#"
            headerStyle={{ width: "3rem" }}
            body={(data, options) => options.rowIndex + 1}
            bodyStyle={{ fontWeight: "bold" }}
          ></Column>
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>
          <Column
            bodyStyle={{ padding: "32px 1rem" }}
            field="serial_number"
            header="numéro de carte SIM"
          ></Column>
          <Column field="nameModele" header="Model"></Column>
          <Column
            field="proprietaire"
            header="Propriétaire"
            body={ownerBody}
          ></Column>
          <Column field="observation" header="Observation"></Column>
          <Column dataType="date" header="Date" body={dateBodyFormat}></Column>
        </DataTable>
        <span style={{ color: "red" }}>
          {errorsMessages && errorsMessages.Articles
            ? errorsMessages.Articles[0]
            : ""}
        </span>
        <div
          style={{
            display: "flex",
            width: "40%",
            gap: "20px",
            padding: "2rem",
            alignItems: "start",
          }}
        >
          <div>
            <Form.Select
              aria-label="Default select example"
              name="technicien"
              onChange={handleAffectedTechnicianOption}
            >
              <option value="none" selected disabled hidden>
                Veuillez choisir un technicien
              </option>
              <option disabled>Veuillez choisir un technicien</option>
              {technicien.map((tech) => (
                <option key={tech.id} value={tech.id}>
                  {tech.name}
                </option>
              ))}
            </Form.Select>
            <span style={{ color: "red" }}>
              {errorsMessages && errorsMessages.technicien
                ? errorsMessages.technicien[0]
                : ""}
            </span>
          </div>
          <Button
            type="submit"
            variant="primary"
            onClick={(e) => SubmittedToTechnician(e, selectedAppareils)}
          >
            Envoyer au technicien
          </Button>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            backgroundColor: "white",
            width: "80%",
            padding: "2rem",
            marginTop: "1rem",
            borderRadius: "2rem",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "80%",
              padding: "1rem",
            }}
          >
            {renderHeaderGlobalSearch()}
          </div>

          <MDBTable align="middle">
            <MDBTableHead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Utilisateur</th>
                <th scope="col">Quantité</th>
                <th scope="col">Appareils</th>
              </tr>
            </MDBTableHead>
            {simPreUser.map((appareilPreUser, index) => (
              <MDBTableBody key={index}>
                <tr>
                  <td>
                    <div className="d-flex align-items-center"></div>
                  </td>
                  <td>
                    <p className="fw-normal mb-1">{appareilPreUser.name}</p>
                  </td>

                  <td>
                    <p className="fw-normal mb-1">
                      {appareilPreUser.numberArticle}
                    </p>
                  </td>
                  <td>
                    <Button
                      className="showAppareilPreUser"
                      variant="primary"
                      onClick={() =>
                        Navigate(
                          `/Support-technique/appareils-pour-l'utilisateur/${appareilPreUser.id}/SIM`
                        )
                      }
                    >
                      voir les appareils
                    </Button>{" "}
                  </td>
                </tr>
              </MDBTableBody>
            ))}
          </MDBTable>
          {appareil.AllGPS.length > 0 ? (
            <nav aria-label="...">
              <MDBPagination className="mb-0 d-flex justify-content-end">
                <MDBPaginationItem disabled>
                  <MDBPaginationLink
                    href="#"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    Pages
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {numberPagesAppareilPreUser.map((numberPage, index) => (
                  <MDBPaginationItem
                    key={index}
                    className={`${numberPage == pages ? "active" : "null"}`}
                  >
                    <MDBPaginationLink
                      style={{ cursor: "pointer" }}
                      onClick={() => setPagesAppareilPreUser(numberPage)}
                    >
                      {numberPage}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination>
            </nav>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SIM);
