import React, { useContext, useState } from "react";
import { FaBell } from "react-icons/fa";
import "../../App.css";
import "./style/topbar.css";
import { useEffect } from "react";
import Slider from "react-slick";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { MDBSpinner } from "mdb-react-ui-kit";
import axios from "../context/axios";
import { AuthContext } from "../context/AuthContext";
import _ from "lodash";
import { useRef } from "react";

const Notification = ({
  status,
  message,
  TechnicianName,
  notificationForST,
  getTaskTech,
}) => {
  const { USE } = useContext(AuthContext);

  const [notificationCount, setNotificationCount] = useState({
    status: "",
    message: "",
  });

  const [Loading, setLoading] = useState(false);
  const [showed, setShowed] = useState(false);
  const [notificationMessages, setNotificationMessages] = useState(null);
  const [showNotifBar, setNotifBar] = useState(true);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setNotificationCount({
      ...notificationCount,
      status: status,
      message: message,
      TechnicianName: TechnicianName,
    });
    if (USE.profil_id == 28) {
      setNotificationCount({
        ...notificationCount,
        status: status,
        message: message,
        TechnicianName: TechnicianName,
      });
    }
  }, [status, message]);

  const remove = async (e, ID) => {
    e.preventDefault();
    console.log(ID);
    const res = await axios.post(`/api/technicien/NotificationUpdate/${ID}`);
    await notificationForST();
    notify();
    setLoading(false);
  };
  const notify = async () => {
    if (USE.profil_id == 2) {
      await notificationForST();
    } else if (USE.profil_id == 1) {
      getTaskTech();
    }
    // if (!showed == true) {
    let notificationArray = notificationCount.message.map((singleNotif) => {
      if (USE.profil_id == 1) {
        return (
          "Nouvelle Tache " +
          singleNotif.cataches.catache +
          " executé par " +
          singleNotif.users.name
        );
      } else if (USE.profil_id == 2 && singleNotif.etat_tache == 3) {
        return (
          "Nouvelle Tache " +
          singleNotif.catache +
          " de client " +
          singleNotif.nomComplet +
          " a été reporté par " +
          singleNotif.user_name
        );
      }
      // else if (USE.profil_id == "28") {
      //   console.log("its a compta");
      //   singleNotif;
      // }
      else if (USE.profil_id == 2) {
        return (
          "Nouvelle tache de confirmation type " +
          singleNotif.catache +
          " envoyée par " +
          singleNotif.user_name +
          " pour " +
          singleNotif.nomComplet
        );
      }
    });
    let dateArray = notificationCount.message.map((singleNotif) => {
      return singleNotif.date_affectation;
    });
    let statuMessageArray = notificationCount.message.map((singleNotif) => {
      return singleNotif.NotifificationStatus;
    });
    let idMessageArray = notificationCount.message.map((singleNotif) => {
      return singleNotif.id;
    });

    const notificationObject = notificationArray.map((message, index) => ({
      message,
      messageDate: dateArray[index],
      statuMessage: statuMessageArray[index],
      id: idMessageArray[index],
    }));

    setNotifBar(() => !showNotifBar);
    console.log(notificationObject);
    setNotificationMessages({
      ...notificationMessages,
      message: notificationObject,
    });
    // }s
  };

  const handleHover = () => {
    setHovered(!hovered);
  };

  const handleShow = () => {
    notify();
    setShowed(!showed);
  };
  useEffect(() => {
    if (notificationMessages !== null) {
      setLoading(true);
    }
  }, [notificationCount]);

  return (
    <div>
      <div className="XiG zI7 iyn Hsu">
        <div
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
          onClick={handleShow}
          className="oy8 zI7 iyn Hsu"
        >
          <div aria-label="" className="zI7 iyn Hsu">
            <div className="XiG zI7 iyn Hsu">
              <div
                aria-disabled="false"
                aria-label="Notifications"
                className="CCY S9z INd Tbt L4E e8F BG7"
                role="button"
                tabindex="0"
              >
                <div
                  className={
                    hovered
                      ? "INd Jea KS5 _HI mQ8 zI7 iyn Hsu"
                      : "INd Jea KS5 mQ8 zI7 iyn Hsu"
                  }
                  style={{ height: "48px", width: "48px" }}
                >
                  {notificationCount?.status > 0 ? (
                    <div className="notificationNumber">
                      <span>{notificationCount?.status}</span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="XiG zI7 iyn Hsu">
                    <svg
                      className="gUZ ztu U9O kVc"
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      aria-label=""
                      role="img"
                    >
                      <path d="M12 24c-1.66 0-3-1.34-3-3h6c0 1.66-1.34 3-3 3zm7-10.83c1.58 1.52 2.67 3.55 3 5.83H2c.33-2.28 1.42-4.31 3-5.83V7c0-3.87 3.13-7 7-7s7 3.13 7 7v6.17z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="newsHubSidebarWrapper"
        style={{
          opacity: showed ? "1" : "0",
          visibility: showed ? "visible" : "hidden",
          transition: showed
            ? "opacity 100ms linear 0s"
            : "visibility 0s ease 100ms,  opacity 100ms linear 0s",
        }}
      >
        {showed ? (
          <div
            tabindex="-1"
            className="VxL _he ho- ojN p6V urM wc1 zI7 iyn Hsu"
            style={{
              height: "calc((100vh - 80px) - 8px)",
              marginTop: " 90px",
              right: "0.5%",
              minWidth: "400px",
              maxWidth: "375px",
              boxShadow: "rgba(0, 0, 0, 0.1) -3px 4px 14px 0px",
              overscrollBehavior: "none",
              top: " 0px",
              transition: "top 100ms linear 0s",
              zIndex: "1000",
            }}
          >
            <div className="jzS un8 C9i TB_" style={{ height: "100%" }}>
              <div
                data-test-id="news-hub-title"
                className="zI7 iyn Hsu noti_container"
              >
                <h1 className="lH1 dyH iFc H2s sAJ O2T tg7 IZT">
                  Notifications
                </h1>
              </div>
              <hr />
              <div
                className="zI7 iyn Hsu"
                style={{
                  clear: "both",
                  height: "calc(100% - 150px)",
                  overflow: "hidden auto",
                  wordBreak: "break-word",
                }}
              >
                <ul className="scrollableList" data-test-id="scrollable-list">
                  {Loading ? (
                    notificationMessages.message.map((notificationMessage) => (
                      <li id="messagesList" key={notificationMessage.id}>
                        <div
                          onClick={(e) => remove(e, notificationMessage.id)}
                          className="XiG lnZ wsz zI7 iyn Hsu"
                          style={{ height: "100% " }}
                        >
                          <div
                            className={
                              notificationMessage.statuMessage == 1
                                ? "JME Jea KS5 RDc hA- ho- jzS wYR wc1 zI7 iyn Hsu messageStatu"
                                : "JME Jea KS5 RDc hA- ho- jzS wYR wc1 zI7 iyn Hsu"
                            }
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            <div
                              className="KS5 hs0 un8 C9i TB_"
                              style={{ width: "100%", paddingLeft: "20px" }}
                            >
                              <div className="KS5 hs0 un8 C9i TB_">
                                <div
                                  className="Jea Ll2 wc1 zI7 iyn Hsu"
                                  style={{ margin: " 0px 8px" }}
                                >
                                  <div className="zI7 iyn Hsu">
                                    <span className="tBJ dyH iFc sAJ O2T zDA IZT swG">
                                      <span data-test-id="updates-link-text">
                                        {notificationMessage.message}
                                      </span>
                                    </span>
                                    <div className="FNs xvE zI7 iyn Hsu">
                                      <div className="tBJ dyH iFc sAJ EdS zDA IZT swG">
                                        {notificationMessage.messageDate}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <MDBSpinner role="status">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Notification;
