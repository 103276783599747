import React, { createContext, useEffect, useState } from "react";
import axios from "./axios";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const STcontext = createContext();

export const STProvider = ({ children }) => {
  const navigateTo = useNavigate();
  const [responseMessage, setResponseMessage] = useState();
  const [checkboxaccessoire, setcheckboxAssessoire] = useState(false);
  const [commercial, setCommercial] = useState([]);
  const [tasksST, setTasksST] = useState(null);
  const [statisticsTasksDashboard, setStatisticsDashboard] = useState([]);
  const [errorsMessages, setErrorsMessages] = useState();
  const [statisticsTasks, setStatistics] = useState([]);
  const [gerantAppareil, setGerantAppareil] = useState({
    numberGPS: null,
    numberSIM: null,
    ACCESSORY: null,
    gpsUserAssociate: [],
    mostmodel: [],
  });
  const [appareil, setAppareil] = useState({
    numberGPS: [],
    numberSIM: [],
    ACCESSORY: [],
    mostmodel: [],
  });

  const [allGps, setAllGps] = useState([]);
  const [allSim, setAllSim] = useState([]);

  const [appareilInstalle, setAppareilInstalle] = useState({
    numberGPS: null,
    SIM: [],
    ACCESSORY: [],
    mostmodel: [],
    final: [],
  });
  const [NewTask, setNewTask] = useState({
    Clients: [],
    TypesTasks: [],
    TypesDevice: [],
    Articles: [],
    SIM: [],
    Accessories: [],
    Users: [],
    Cities: [],
  });
  const [AffectedArticle, setAffectedArticle] = useState({
    technicien: "",
  });

  const [CheckBoxAffectedArticle, setCheckBoxAffectedArticle] = useState([]);
  const [technicien, setTechnicien] = useState([]);

  const [getStatisticsTasks, setGetStatisticsTasks] = useState({
    categoreyTask: 1,
    etaTask: 2,
    date: "weeks",
  });
  //error messege Function
  const showErrorMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // Success Message toast
  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // get all tasks (validate[1,2] and where data affectation)
  const getTask = async (dateDebut, dateFin, profil) => {
    try {
      const response = await axios.get(
        `/api/supportTechnique/technicien/${dateDebut}/${dateFin}/${profil}`
      );
      setStatisticsDashboard(response.data);
    } catch (error) {
      showErrorMessage("data is required");
    }
  };
  const handleChangeDateStatistics = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setGetStatisticsTasks({
      ...getStatisticsTasks,
      [name]: value,
    });
  };

  const getStatisticsTasksTechnicien = () => {
    axios
      .get(
        `/api/supportTechnique/statisticsTasks/${getStatisticsTasks.etaTask}/${getStatisticsTasks.categoreyTask}/${getStatisticsTasks.date}`
      )
      .then((response) => {
        setStatistics(response.data);
      });
  };

  const [isFilterTask, setIsFilterTask] = useState(false);

  //filter Tasks preDate by date fin realisation
  const filteringPerDate = async (startDate, endDate, profil) => {
    const date = [startDate, endDate];

    const dateFromTo = {
      date_presionnel_début: startDate,
      date_presionnel_fin: endDate,
    };
    sessionStorage.setItem("dateFilter", JSON.stringify(dateFromTo));

    setIsFilterTask(true);
    getTask(startDate, endDate, profil);
    getAppareilInstalle(startDate, endDate);
    axios
      .get(
        `/api/supportTechnique/statisticsTasks/${getStatisticsTasks.etaTask}/${getStatisticsTasks.categoreyTask}/${date}`
      )
      .then((response) => {
        setStatistics(response.data);
      });
  };
  const handleAffectedArticleOption = (CheckBoxAffectedArticle) => {
    setAffectedArticle({
      ...AffectedArticle,
      Articles: CheckBoxAffectedArticle,
    });
  };

  const handleAffectedTechnicianOption = (e) => {
    const { name, value } = e.target;
    setAffectedArticle({
      ...AffectedArticle,
      [name]: value,
    });
  };

  useEffect(() => {
    setAffectedArticle((prevAffectedArticle) => ({
      ...prevAffectedArticle,
      Articles: CheckBoxAffectedArticle,
    }));
  }, [CheckBoxAffectedArticle]);

  const technicianAll = async () => {
    await axios.get("/api/supportTechnique/techniciesAll").then((res) => {
      setTechnicien(res.data);
    });
  };

  const handleAffectedCheckBox = (e, article) => {
    const { name, checked, id } = e.target;
    if (article.serial_number === id) {
      if (checked === true) {
        setAffectedArticle({
          ...AffectedArticle,
          [name]: checked,
          IMEI: article.serial_number,
        });
      } else {
        setAffectedArticle({ ...AffectedArticle, [name]: false, IMEI: "" });
      }
    }
  };

  const SubmittedToTechnician = async () => {
    try {
      await axios.post(
        "/api/supportTechnique/AffectedArticle",
        AffectedArticle
      );
      setErrorsMessages();
      getAppareil();
      getGerantAppareil();
      setAffectedArticle({ ...AffectedArticle, Articles: null });
    } catch (error) {
      setErrorsMessages(error.response.data.errors);
    }
  };

  const getAppareil = async () => {
    const response = await axios.get("/api/supportTechnique/AllAppareils");
    setAppareil(response.data);
  };
  const getAllGps = async () => {
    const response = await axios.get("/api/supportTechnique/AllGps");
    setAllGps(response.data);
  };
  const getAllSim = async () => {
    const response = await axios.get("/api/supportTechnique/AllSim");
    setAllSim(response.data);
  };

  const getGerantAppareil = async () => {
    const response = await axios.get("/api/gerant/allAppareils");
    setGerantAppareil(response.data);
  };

  //show all appareil per user
  const [apparielsPerUser, setAppareilPerUser] = useState([]);
  const [showApparielPreUser, setShowAppareilPerUser] = useState(false);
  const getAppareilsPerUser = async (userId, typeId) => {
    setShowAppareilPerUser(true);
    const response = await axios.get(
      `/api/gerant/getAppareilsPerUser/${userId}/${typeId}`
    );
    setAppareilPerUser(response.data);
  };

  const getAppareilInstalle = async (startDate, endDate) => {
    const response = await axios.get(
      `/api/supportTechnique/AllAppareilInstalle/${startDate}/${endDate}`
    );
    setAppareilInstalle(response.data);
  };

  //start all tasks function's
  //get all task Of commercial
  const getCommercial = async () => {
    const response = await axios.get("/api/supportTechnique/commercial");
    setCommercial(response.data);
  };
  // end get all task Of   commercial

  //get all task Of ST
  const getStTaches = async () => {
    const res = await axios.get("/api/supportTechnique/technicienTask");
    setTasksST(res.data.taskSupportTechnique);
  };
  //end get all task Of ST

  const [vehiclesPreClient, setVehiclesPreClient] = useState(null);

  const getAllVehiclesPreClient = async (client_id) => {
    const response = await axios.get(
      `/api/supportTechnique/all-vehicles-pre-client/${client_id}`
    );
    setVehiclesPreClient(response.data);
  };

  const [endTaskST, setEndTaskST] = useState({});
  const handleEndTaskST = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEndTaskST({ ...endTaskST, [name]: value });
  };

  const completTaskSubmit = async (taskId) => {
    try {
      await axios.post(
        `/api/supportTechnique/complet-task/${taskId}`,
        endTaskST
      );
      getStTaches();
      navigateTo("SupportTechnique/tâches/supporttechnique");
    } catch (err) {
      console.log(err);
    }
  };

  const [delayTaskST, setDelayTaskST] = useState({});
  const handleDelayTaskSTChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDelayTaskST({ ...delayTaskST, [name]: value });
  };

  const delayTaskSubmit = async (taskId) => {
    try {
      await axios.post(
        `/api/supportTechnique/delay-task/${taskId}`,
        delayTaskST
      );
      getStTaches();
      navigateTo("SupportTechnique/tâches/supporttechnique");
    } catch (error) {
      console.log(error);
    }
  };

  const [cancelTaskST, setCancelTaskST] = useState({});

  const handleCancelTaskSTChange = (e) => {
    const { name, value } = e.target;
    setCancelTaskST({ ...cancelTaskST, [name]: value });
  };

  const cancelTaskSubmit = async (taskId) => {
    try {
      await axios.post(
        `/api/supportTechnique/cancel-task/${taskId}`,
        cancelTaskST
      );
      getStTaches();
      navigateTo("SupportTechnique/tâches/supporttechnique");
    } catch (error) {
      console.log(error);
    }
  };

  //get all tasks to confirmed for ST
  const [taskToConfirmed, setTaskToConfirmed] = useState([]);
  const getTasktoConfirmed = async () => {
    await axios.get("/api/supportTechnique/tasksToConfirmed").then((res) => {
      setTaskToConfirmed(res.data);
    });
  };

  //end get all tasks to confirmed for ST

  //update task to confirmed from ST

  const confirmTask = async (e, UpdateTaskToConfirmed, idTask) => {
    e.preventDefault();
    try {
      await axios.post(
        `/api/supportTechnique/confirmedTask/${idTask}`,
        UpdateTaskToConfirmed
      );
      showSuccessMessage("La tâche a été validée");
      getTasktoConfirmed();
      setErrorsMessages();
    } catch (error) {
      setErrorsMessages(error.response.data.errors);
    }
  };
  const deleteTaskToConfirmed = async (e, id) => {
    try {
      await axios.put(`/api/supportTechnique/deleteTaskToConfirmed/${id}`);
      showSuccessMessage("La tâche a été supprimée");
      getTasktoConfirmed();
      setErrorsMessages();
    } catch (e) {
      showErrorMessage("vous ne pouvez pas supprimer cette tâche");
    }
  };

  // end update task to confirmed from ST

  const validateTasks = async (e, idTask) => {
    const is_validate = e.target.value;
    await axios.put(
      `/api/supportTechnique/validatedTask/${idTask}/${is_validate}`
    );
    filteredData != null && filteredData.length > 0
      ? handleSearchTechnicianTaskPerDateClicked(1)
      : supportTechiqueTechnicianTask();
  };
  //end all tasks function's

  const [filterData, setFilteredData] = useState(null);

  const [inputDate, setInputDate] = useState({ startDate: "", endDate: "" });

  const handleDateChange = async (e) => {
    const { name, value } = e.target;
    setInputDate({ ...inputDate, [name]: value });
  };

  const handleDateClick = async (e) => {
    const res = await axios.get(
      `/api/supportTechnique/searchDateClient/${inputDate.startDate}/${inputDate.endDate}`
    );
    setFilteredData(res.data);
  };

  const getTaskInfo = async () => {
    const response = await axios.get("/api/supportTechnique/newTask");
    setNewTask(response.data);
  };

  //Handle Add New Task To Technicien

  const [AddNewTask, setAddNewTask] = useState({
    telephone: "",
    emailClient: "",
    Quantite: 1,
  });

  const handleAddTaskToTechnicien = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddNewTask({ ...AddNewTask, [name]: value });
  };
  //add new task to technicien
  const [task, setSTTEchnicianTask] = useState([]);
  const [show, setShow] = useState(false);

  const supportTechiqueTechnicianTask = async () => {
    const res = await axios.get("/api/supportTechnique/technicienTask");
    setSTTEchnicianTask(res.data.taskTechniciens);
  };

  const addTaskToTechnicien = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/supportTechnique/newTask/store", AddNewTask);
      setShow(false);
      showSuccessMessage("tâche ajouter  avec succès");
      supportTechiqueTechnicianTask();
      getStTaches();
      setAddNewTask({
        Quantite: 1,
      });
      setErrorsMessages();
    } catch (error) {
      setErrorsMessages(error.response.data.errors);
    }
  };

  const handleCheckBoxAccChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setcheckboxAssessoire(!checkboxaccessoire);
  };

  // handelCombinedChange

  //get all task commercial
  const [taskCommercial, setTaskCommercial] = useState([]);

  const getClient = async () => {
    const res = await axios.get("/api/supportTechnique/commercial");
    setTaskCommercial(res.data);
  };

  //end task commercial

  const [addCombination, setAddCombination] = useState({
    GPSID: "",
  });

  const handelCombinedChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddCombination({ ...addCombination, [name]: value });
  };

  const SubmitSimCombinaison = async (e, id) => {
    e.preventDefault();
    const res = await axios.post("/api/supportTechnique/assignmentSimToGps", {
      ...addCombination,
      GPSID: id,
    });
    getAppareil();
  };

  const [filterDataDashboardByDate, setFilterDataDashboardBydate] = useState({
    date_presionnel_début: "",
    date_presionnel_fin: "",
  });

  const handleFilteredTaskByDate = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setFilterDataDashboardBydate({
      ...filterDataDashboardByDate,
      [name]: value,
    });
  };

  const SubmittedFilteredDashboardDate = async () => {
    const res = axios.get(
      `/api/supportTechnique/filteredDashboardData/`,
      filterDataDashboardByDate
    );
  };

  const [UpdateTaskToConfirmed, setUpdateTaskToConfirmed] = useState({
    nomClient: "",
  });

  const handleUpdateTaskToConfirmed = (e) => {
    const name = e.taget.name;
    const value = e.taget.value;
    setUpdateTaskToConfirmed({ ...UpdateTaskToConfirmed, [name]: value });
  };

  const [inputTechnicianSearch, setInputTechnicianSearch] = useState();

  const [filteredData, setDataFiltering] = useState(null);

  const handleTEchnicianSearchClick = async (e) => {
    const res = await axios.get(
      `/api/supportTechnique/searchTechnician/${inputTechnicianSearch}`
    );
    setDataFiltering(res.data);
  };

  const [searchnInputTEchnicianPerDate, setSearchInputTechnicianPerDate] =
    useState({
      startDate: "",
      endDate: "",
    });

  const handleSearchTechnicianTaskPerDate = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setSearchInputTechnicianPerDate({
      ...searchnInputTEchnicianPerDate,
      [name]: value,
    });
  };

  const handleSearchTechnicianTaskPerDateClicked = async (profil_id) => {
    try {
      const res = await axios.get(
        `/api/supportTechnique/searchTechnicianByDate/${profil_id}`,
        {
          params: {
            startDate: searchnInputTEchnicianPerDate.startDate,
            endDate: searchnInputTEchnicianPerDate.endDate,
          },
        }
      );
      setDataFiltering(res.data);
    } catch (error) {
      showErrorMessage(error.response.data.errors.startDate[0]);
    }
  };

  const [inputStSearch, setInputStSearch] = useState();

  const handleSTChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value != "") {
      setInputStSearch(value);
    }
    if (value == "") {
      supportTechiqueTechnicianTask();
      setDataFiltering(null);
    }
  };

  const handleStSearchClick = async () => {
    const res = await axios.get(
      `/api/supportTechnique/searchStTask/${inputStSearch}`
    );
    setDataFiltering(res.data);
  };

  const [InputStTaskperDate, setSearchStTaskperDate] = useState({
    startDate: "",
    endDate: "",
  });

  const handlefilteredStTaskPerDate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSearchStTaskperDate({ ...InputStTaskperDate, [name]: value });
  };

  const handleFilterStTaskClick = async () => {
    const res = await axios.get(
      `/api/supportTechnique/searchStByDate/${InputStTaskperDate.startDate}/${InputStTaskperDate.endDate}`
    );
    setDataFiltering(res.data);
  };

  const handleChangeTaskST = (TASK) => {
    axios.post(`/api/supportTechnique/Task/${TASK.tache_id}`);
  };

  const deleteTaskTechnicien = async (id) => {
    try {
      await axios.delete(`api/supportTechnique/deleteTask/${id}`);
      filteredData != null && filteredData.length > 0
        ? handleSearchTechnicianTaskPerDateClicked(1)
        : supportTechiqueTechnicianTask();
      showSuccessMessage("tâche supprimer  avec succès");
    } catch (error) {
      showErrorMessage("impossible de supprimer cette tâche");
    }
  };

  const [modeles, setModeles] = useState([]);

  const handleGetAllModeles = async () => {
    const response = await axios.get("/api/supportTechnique/all-modeles");
    setModeles(response.data);
  };
  const [apprareilReturned, setAppareilReturned] = useState([]);

  const getReturnedDevices = () => {
    axios.get("/api/supportTechnique/ReturnedDevice").then((res) => {
      setAppareilReturned(res.data);
    });
  };

  const confirmedReturnDevice = (deviceToReturned, deviceId) => {
    try {
      axios.post(
        `/api/supportTechnique/return-device-to-ST/${deviceId}`,
        deviceToReturned
      );
      showSuccessMessage("l'appareil est revenu avec succès");
      getAppareil();
    } catch (e) {
      setErrorsMessages(e);
    }
  };

  // change endroit appareil from appareils returned to appareils(endamage, bon occasion, new, client)

  const [changeEndroitAppareils, setchangeEndroitAppareils] = useState({});

  const handleChangeEndroitAppareils = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setchangeEndroitAppareils({ ...changeEndroitAppareils, [name]: value });
  };
  const confirmeAcceptanceOfTheDevice = async (e, id) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `api/supportTechnique/confirmeAcceptanceOfTheDevice/${id}`,
        changeEndroitAppareils
      );
      setResponseMessage(response.status);
      getReturnedDevices();
      setchangeEndroitAppareils({});
      setErrorsMessages(null);
    } catch (e) {
      setErrorsMessages(e.response.data.errors);
    }
  };

  const handleChangeEditAppareil = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex]; // Get the selected option
    const data_special = selectedOption.getAttribute("data-special");
    setAffectedArticle({
      ...AffectedArticle,
      [name]: value,
      special: data_special,
    });
  };
  // edit endroit technicien appareil from st
  const editAppareilsPreUser = async (e, userId, typeId) => {
    const response = await axios.put(
      "/api/supportTechnique/editEndroitAppareil",
      AffectedArticle
    );
    setResponseMessage(response.status);
    getAppareilsPerUser(userId, typeId);
  };

  // import sim from ST
  const [importSim, setImportSim] = useState({});
  const [simNotImporter, setSimNotImporter] = useState([]);
  const handleImportSim = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setImportSim({ ...importSim, [name]: value });
  };

  const readExcelFile = async (file) => {
    const readerFile = new FileReader();
    readerFile.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      setImportSim({
        ...importSim,
        simNumber: XLSX.utils
          .sheet_to_json(ws, {
            header: 1,
            raw: false,
          })
          .flat(),
      });
    };
    readerFile.readAsArrayBuffer(file);
  };
  const [showImportSim, setShowImportSim] = useState(false);

  const importSimSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "api/supportTechnique/import-sim",
        importSim
      );
      response.data.sim_not_found.length === 0
        ? setShowImportSim(false)
        : setShowImportSim(true);
      getAppareil();
      setSimNotImporter(response.data.sim_not_found);
    } catch (err) {
      console.log(err);
    }
  };
  // end import sim function

  // add new sim function

  const [addNewSim, setAddNewSim] = useState({});
  const handleChangeAddNewSim = (e) => {
    const { name, value } = e.target;
    setAddNewSim({ ...addNewSim, [name]: value });
  };
  // target the value of the input
  const inputArr = [
    {
      type: "text",
      id: 1,
      value: "",
    },
  ];
  const [arr, setArr] = useState(inputArr);
  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        {
          type: "text",
          value: "",
          type_id: "",
        },
      ];
    });
  };
  const handleChangeSimNumber = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setArr((s) => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;
      return newArr;
    });
  };

  useEffect(() => {
    let simNumbers = [];
    arr &&
      arr.map((inputValue) =>
        inputValue.value != "" ? simNumbers.push(inputValue.value) : null
      );
    setAddNewSim({
      ...addNewSim,
      Serie: [...(simNumbers ? simNumbers : "")],
    });
  }, [arr]);
  const handleAddNewSimSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/supportTechnique/add-new-sim", addNewSim);
      await getAppareil();
      setAddNewSim({});
      showSuccessMessage("sim ajouter avec succès");
    } catch (error) {
      setErrorsMessages(error.response.data.errors);
    }
  };

  // end add new sim function
  const handleConfirmAction = async (id) => {
    try {
      await axios.delete(`api/supportTechnique/deleteArticles/${id}`);
      getAppareil();
    } catch (error) {
      console.log(error);
    }
  };
  const [globalSearch, setGlobalSearch] = useState({
    search: "",
    appareilId: "",
  });
  const [globalSearchRes, setGlobalSearchRes] = useState(null);
  const handleGlobalSearch = (e, appareilId) => {
    setGlobalSearch({
      ...globalSearch,
      search: e.target.value,
      appareilId: appareilId,
    });
  };

  useEffect(() => {
    if (globalSearch.search != "") {
      const getGlobalSearch = async () => {
        const response = await axios.get(
          `api/supportTechnique/globalSearch/${globalSearch.appareilId}/${globalSearch.search}`
        );
        setGlobalSearchRes(response.data);
      };
      getGlobalSearch();
    } else {
      setGlobalSearchRes(null);
    }
  }, [globalSearch]);

  //log devices for manager
  const [logDevices, setLogDevices] = useState([]);
  const getLogOfDevice = async (device) => {
    try {
      const response = await axios.get(
        `/api/gerant/allAppareils/logDevice/${device}`
      );
      setLogDevices(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <STcontext.Provider
      value={{
        handleUpdateTaskToConfirmed,
        UpdateTaskToConfirmed,
        getTask,
        tasksST,
        handleEndTaskST,
        completTaskSubmit,
        handleDelayTaskSTChange,
        delayTaskST,
        setDelayTaskST,
        delayTaskSubmit,
        handleCancelTaskSTChange,
        cancelTaskSubmit,
        getTasktoConfirmed,
        confirmTask,
        validateTasks,
        deleteTaskToConfirmed,
        taskToConfirmed,
        getAppareil,
        getAllGps,
        getAllSim,
        allGps,
        setAllGps,
        allSim,
        setAllSim,
        appareil,
        getCommercial,
        commercial,
        filterData,
        handleDateChange,
        inputDate,
        handleDateClick,
        getTaskInfo,
        statisticsTasksDashboard,
        NewTask,
        getAppareilInstalle,
        appareilInstalle,
        getGerantAppareil,
        gerantAppareil,
        AffectedArticle,
        handleAffectedCheckBox,
        handleAffectedTechnicianOption,
        SubmittedToTechnician,
        technicianAll,
        technicien,
        handleAddTaskToTechnicien,
        AddNewTask,
        handleCheckBoxAccChange,
        checkboxaccessoire,
        handleAffectedArticleOption,
        CheckBoxAffectedArticle,
        setAddNewTask,
        addTaskToTechnicien,
        supportTechiqueTechnicianTask,
        task,
        setAffectedArticle,
        setCheckBoxAffectedArticle,
        handelCombinedChange,
        addCombination,
        setAddCombination,
        SubmitSimCombinaison,
        handleFilteredTaskByDate,
        SubmittedFilteredDashboardDate,
        handleTEchnicianSearchClick,
        filteredData,
        setDataFiltering,
        handleSearchTechnicianTaskPerDate,
        handleSearchTechnicianTaskPerDateClicked,
        searchnInputTEchnicianPerDate,
        setSearchInputTechnicianPerDate,
        getClient,
        taskCommercial,
        handleSTChange,
        inputStSearch,
        handleStSearchClick,
        handleFilterStTaskClick,
        handlefilteredStTaskPerDate,
        InputStTaskperDate,
        getStTaches,
        getAllVehiclesPreClient,
        vehiclesPreClient,
        show,
        setShow,
        isFilterTask,
        setIsFilterTask,
        setTasksST,
        getStatisticsTasks,
        statisticsTasks,
        getStatisticsTasksTechnicien,
        handleChangeDateStatistics,
        filteringPerDate,
        setStatistics,
        getAppareilsPerUser,
        apparielsPerUser,
        setAppareilPerUser,
        showApparielPreUser,
        setShowAppareilPerUser,
        handleChangeTaskST,
        apprareilReturned,
        getReturnedDevices,
        confirmedReturnDevice,
        confirmeAcceptanceOfTheDevice,
        deleteTaskTechnicien,
        editAppareilsPreUser,
        responseMessage,
        setResponseMessage,
        handleChangeEditAppareil,
        handleChangeEndroitAppareils,
        setchangeEndroitAppareils,
        changeEndroitAppareils,
        handleImportSim,
        readExcelFile,
        importSimSubmit,
        showImportSim,
        setShowImportSim,
        simNotImporter,
        setSimNotImporter,
        errorsMessages,
        setErrorsMessages,
        handleConfirmAction,
        handleGlobalSearch,
        setGlobalSearch,
        globalSearch,
        globalSearchRes,
        handleGetAllModeles,
        modeles,
        addInput,
        setArr,
        arr,
        handleChangeSimNumber,
        handleChangeAddNewSim,
        handleAddNewSimSubmit,
        getLogOfDevice,
        logDevices,
      }}
    >
      {children}
    </STcontext.Provider>
  );
};
export default STcontext;
