import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import Dropdown from "react-bootstrap/Dropdown";
import NotificationsIcon from "@mui/icons-material/Notifications";
import "./style/topbar.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import STcontext from "../context/STcontext";
import axios from "../context/axios";
import Notification from "./Notification";
import LogoutIcon from "@mui/icons-material/Logout";
import pusher from "../../pusher";

const NavigationBar = () => {
  const { handleLogOut, USE, notification, setNotification } =
    useContext(AuthContext);
  const {
    getCOmptaAppareil,
    isLogicDone,
    notificationCompta,
    setNotificationCompta,
    setCounter,
    updatedTheStatus,
  } = useContext(AuthContext);

  const [notif, setNotif] = useState([]);
  const [notificationST, setNotificationSt] = useState({
    message: "",
    TechnicianName: "",
    status: "",
  });
  const getTaskTech = async () => {
    const res = await axios.get("/api/technicien/tâches");
    setNotification({
      ...notification,
      status: res.data
        ? res.data.filter((task) => task.NotifificationStatus == 1).length
        : "",
      message: res.data ? res.data : "",
      TechnicianName: USE.name,
    });
  };
  // const getTaskCommercial = async()=>{
  //   const res = await axios.get('/api/clients')
  //   console.log(res.data)
  // }
  useEffect(() => {
    getTaskTech();
  }, []);
  // const [notificationCompta,setNotificationCompta]=useState({
  //   status:"",
  //   message:[]
  // })

  //notification ST

  // const notificationForST = async () => {
  //   const notificationTasksToConfirmed = await axios.get(
  //     "/api/supportTechnique/tasksToConfirmed"
  //   );
  // const notificationTasksPostponed = await axios.get(
  //   "/api/supportTechnique/reportedTask"
  // );
  // setNotificationSt({
  //   ...notificationST,
  //   status: [
  //     ...(notificationTasksToConfirmed.data
  //       ? notificationTasksToConfirmed.data.filter(
  //           (obj) => obj.NotifificationStatus == 1
  //         )
  //       : ""),
  //     ...(notificationTasksPostponed.data
  //       ? notificationTasksPostponed.data.filter(
  //           (taskToConfirm) => taskToConfirm.NotifificationStatus == 1
  //         )
  //       : ""),
  //   ].length,
  //   message: [
  //     ...(notificationTasksToConfirmed.data
  //       ? notificationTasksToConfirmed.data
  //       : ""),
  //     ...(notificationTasksPostponed.data
  //       ? notificationTasksPostponed.data
  //       : ""),
  //   ],
  //   TechnicianName: USE.name,
  // });
  // setNotificationSt({...notificationST,status:responseST.data.filter((taskToConfirm)=>taskToConfirm.NotifificationStatus ==1).length,message:responseST.data,TechnicianName:USE.name})
  // };

  //connection with pusher
  // const channel = pusher.subscribe("realTimeGetData");
  // channel.bind("realTimeUpdateData", (data) => {
  //   notificationForST();
  // });

  //end connection with pusher

  // Clean up the Pusher connection when the component unmounts
  // useEffect(() => {
  //   return () => {
  //     pusher.unsubscribe("realTimeGetData");
  //   };
  // }, []);

  // const [counter,setCounter]=useState(0)
  // const [isLogicDone, setIsLogicDone] = useState(false);

  // const getCOmptaAppareil = async()=>{
  //   const res = await axios.get('/api/comptable/allAppareils');
  //    console.log("indeed compta")
  //   console.log(res.data)
  //   if(res.data.NumberGps < 10){
  //     console.log("gps passed")
  //     setCounter((counter)=>counter+1)
  //     setNotificationCompta(prevState => ({
  //       ...prevState,
  //       message: [...prevState.message, "GPS Almost Done"]
  //     }));      setIsLogicDone(!isLogicDone)
  //   }
  //    if  (res.data.NumberSIM < 10){
  //     console.log("sim passed")
  //     setCounter((counter)=>counter+1)
  //     setNotificationCompta(prevState => ({
  //       ...prevState,
  //       message: [...prevState.message, "SIM Almost Done"]
  //     }));      setIsLogicDone(!isLogicDone)

  //     }
  //    if(res.data.NumberAccessory <10){
  //     console.log("Acc passed")
  //     console.log(notificationCompta.message)
  //       setCounter((counter)=>counter+1)
  //       setNotificationCompta(prevState => ({
  //         ...prevState,
  //         message: [...prevState.message, "ACC Almost Done"]
  //       }));        console.log([...notificationCompta.message])
  //       setIsLogicDone(!isLogicDone)

  //     }
  //     if(res.data.NumberGps > 10){
  //       console.log("gps more than 10 ")
  //       setCounter((counter)=>counter-1)
  //       setNotificationCompta(prevState => ({
  //         ...prevState,
  //         message: [...prevState.message, ""]
  //       }));      setIsLogicDone(!isLogicDone)
  //     }
  //      if  (res.data.NumberSIM > 10){
  //       console.log("sim more than 10")
  //       setCounter((counter)=>counter-1)
  //       setNotificationCompta(prevState => ({
  //         ...prevState,
  //         message: [...prevState.message, ""]
  //       }));      setIsLogicDone(!isLogicDone)

  //       }
  //      if(res.data.NumberAccessory >10){
  //       console.log("Acc more than 10")
  //       console.log(notificationCompta.message)
  //         setCounter((counter)=>counter-1)
  //         setNotificationCompta(prevState => ({
  //           ...prevState,
  //           message: [...prevState.message, ""]
  //         }));        console.log([...notificationCompta.message])
  //         setIsLogicDone(!isLogicDone)

  //       }

  // }
  //   const updatedTheStatus = ()=>{
  //     console.log("triggered")
  //     setNotificationCompta(prevState => ({
  //       ...prevState,
  //       status: counter
  //     }));  }
  // console.log(counter)
  useEffect(() => {
    // notificationForST();
    if (USE.profil_id == 28) {
      getCOmptaAppareil();
    }
  }, []);

  useEffect(() => {
    updatedTheStatus();
  }, [isLogicDone]);
  const showdate = new Date();
  let day = showdate.getDate();
  const displaydate =
    showdate.getDate() +
    "/" +
    showdate.getMonth() +
    "/" +
    showdate.getFullYear();
  const displayTime = showdate.getHours() + ":" + showdate.getMinutes();
  const dt = showdate.toDateString();

  return (
    <nav className="navBar">
      <div className="ContainerTitle">
        <h5 className="nameProfil">
          Bonjour {USE == null ? "utilisateur" : USE.name}
        </h5>
        <span>
          {displayTime} {dt}
        </span>
      </div>
      <div className="profilContainer">
        {USE.profil_id == 1 ? (
          <button className="profil">
            <Notification
              status={notification.status}
              message={notification.message}
              TechnicianName={USE.name}
              profileID={USE.id}
              getTaskTech={getTaskTech}
            />
          </button>
        ) : (
          ""
        )}

        {USE.profil_id == 2 ? (
          <button className="profil">
            <Notification
              status={notificationST.status}
              message={notificationST.message}
              TechnicianName={USE.name}
              profileID={USE.id}
              // notificationForST={notificationForST}
            />
          </button>
        ) : (
          ""
        )}

        {USE.profil_id == 28 ? (
          <button className="profil">
            <Notification
              setCounter={setCounter}
              notificationCompta={notificationCompta}
              setNotificationCompta={setNotificationCompta}
              status={notificationCompta.status}
              message={notificationCompta.message}
              TechnicianName={USE.name}
              profileID={USE.id}
              getCOmptaAppareil={getCOmptaAppareil}
            />
          </button>
        ) : (
          ""
        )}

        <button className="profil">
          <LogoutIcon onClick={handleLogOut} />
        </button>
      </div>
    </nav>
  );
};

export default NavigationBar;
