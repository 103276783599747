import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ComptableContext from "../../../context/ComptableContext";
import axios from "../../../context/axios";
import STcontext from "../../../context/STcontext";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import MessageAdd from "./messages/MessageAdd";

export default function AddDevices() {
  const { alltype, getAllTypes } = useContext(ComptableContext);
  const { getAppareil } = useContext(STcontext);
  const [existingDevice, setExistingDevice] = useState({});
  const [ModelAppareil, setModelAppareil] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    getAllTypes();
  }, []);

  //error messege Function
  const showErrorMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  // Success Message toast
  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const deviceWith = (params) => {
    switch (params.latest_endroit[0].id) {
      case 4:
        return "ST";
      case 3:
        return params.users[0].name;
      default:
        return null;
    }
  };
  const handleExistingDevice = (response) => {
    response.map((res) => {
      if (res.existingDevice == true) {
        switch (res.device.latest_endroit[0].id) {
          case 5:
            setExistingDevice(
              response.filter(
                (returnDevice) => returnDevice.device.latest_endroit[0].id == 5
              )
            );
            setModalShow(true);
            setShow(false);
            break;
          case 7:
            showSuccessMessage("l'appareil a été retourné avec succès");
            handleClose();
            break;
          default:
            showErrorMessage(
              `${res.device.serial_number} chez ${deviceWith(res.device)}`
            );
        }
      } else {
        showSuccessMessage("l'appareil a été ajouté avec succès");
        handleClose();
      }
    });
  };
  const [errorMessage, setErrorMessage] = useState();

  const handleTheTypeChange = (e) => {
    e.preventDefault();
    const ID = e.target.value;
    setModelAppareil(alltype.filter((type) => type.id == ID));
    e.preventDefault();
    setAppareilAdd({ ...AppareilAdd, type_id: "", Serie: "" });
    setArr([
      {
        type: "text",
        id: 1,
        value: "",
      },
    ]);
    setSentData({ ...sentData, Serie: [] });
  };

  const [AppareilAdd, setAppareilAdd] = useState({
    Modele_id: "",
    Series: [],
  });

  const [show, setShow] = useState(false);
  const handleClose = () => {
    getAppareil();
    setSentData("");
    setAppareilAdd("");
    setModelAppareil("");
    setModalShow(false);
    setErrorMessage("");
    setArr([
      {
        type: "text",
        value: "",
        type_id: "",
      },
    ]);
    setShow(false);
  };
  const closeMessageModel = () => {
    setModalShow(false);
    handleShow();
  };
  const handleShow = () => setShow(true);
  const inputArr = [
    {
      type: "text",
      id: 1,
      value: "",
    },
  ];
  const [arr, setArr] = useState(inputArr);

  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        {
          type: "text",
          value: "",
          type_id: "",
        },
      ];
    });
  };
  const [sentData, setSentData] = useState({});
  const handleAddClientAppareilsChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setAppareilAdd({ ...AppareilAdd, [name]: value });
    setSentData({ ...sentData, [name]: value });
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const index = e.target.id;
    setArr((s) => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;
      newArr[index].Modele_id = AppareilAdd.Modele_id;
      return newArr;
    });
    setAppareilAdd({ ...AppareilAdd, [name]: value });
  };

  useEffect(() => {
    setSentData({
      ...sentData,
      Serie: [...(arr ? arr.map((obj) => obj.value) : "")],
    });
  }, [arr]);

  // start add new articles
  const addNewArticle = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "api/supportTechnique/add-devices",
        sentData
      );
      handleExistingDevice(response.data);
    } catch (err) {
      setErrorMessage(err);
    }
  };

  const handleRemoveInput = (index) => {
    const newForm = [...arr];
    newForm.splice(index, 1);
    delete errorMessage[`Serie.${index}`];
    setArr(newForm);
  };

  //end add new articles

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <AddIcon /> Ajouter Appareils{" "}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter Appareils</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={addNewArticle}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>Type d'appareil</Form.Label>
                <Form.Select
                  onChange={handleTheTypeChange}
                  aria-label="Default select example"
                >
                  <option value="none" selected disabled hidden>
                    Select Type
                  </option>
                  <option disabled>Select Type</option>
                  {alltype.map((types, index) => (
                    <option key={index} value={types.id}>
                      {types.nameType}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>Modèle appareil</Form.Label>
                <Form.Select
                  onChange={handleAddClientAppareilsChange}
                  aria-label="Default select example"
                  name="modele_id"
                >
                  <option value="none" selected disabled hidden>
                    Select Model
                  </option>
                  <option disabled>Select Model</option>
                  {ModelAppareil
                    ? ModelAppareil.map((Model) =>
                        Model.modeles.map((model) => (
                          <option key={model.id} value={model.id}>
                            {model.nameModele}
                          </option>
                        ))
                      )
                    : ""}
                </Form.Select>
                <span style={{ color: "red" }}>
                  {errorMessage && errorMessage.modele_id
                    ? errorMessage.modele_id[0]
                    : ""}
                </span>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>Propriétaire d'appareil</Form.Label>
                <Form.Select
                  onChange={handleAddClientAppareilsChange}
                  name="deviceOwner"
                >
                  <option value="none" selected disabled hidden>
                    Propriétaire d'appareil
                  </option>
                  <option disabled>Select propriétaire</option>
                  <option value={1}>Client</option>
                  <option value={0}>D3 HI-TECH</option>
                </Form.Select>
                <span style={{ color: "red" }}>
                  {errorMessage && errorMessage.deviceOwner
                    ? errorMessage.deviceOwner[0]
                    : ""}
                </span>
              </Form.Group>
              {arr
                ? arr.map((item, i) => {
                    return (
                      <Form.Group
                        key={i}
                        as={Col}
                        md="6"
                        controlId="validationCustom02"
                      >
                        <Form.Label size="40">Serie N°:</Form.Label>
                        <Col style={{ display: "flex", gap: "15px" }}>
                          <Col>
                            <input
                              className="form-control"
                              onChange={handleChange}
                              value={item.value}
                              id={i}
                              type={item.type}
                              size="40"
                              name="Serie"
                              autoComplete="off"
                            />
                            {errorMessage &&
                              errorMessage[`Serie.${i}`] &&
                              errorMessage[`Serie.${i}`].map(
                                (message, index) => (
                                  <span
                                    style={{ color: "red" }}
                                    key={index}
                                    className="error-message"
                                  >
                                    {message}
                                  </span>
                                )
                              )}
                          </Col>
                          <Button
                            variant="outline-danger"
                            style={{ maxHeight: "42px" }}
                            onClick={() => handleRemoveInput(i)}
                          >
                            <ClearIcon />
                          </Button>
                        </Col>
                      </Form.Group>
                    );
                  })
                : ""}

              {/* </Form.Group> */}
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="valid">
                <Button variant="secondary" onClick={addInput}>
                  <AddCircleIcon />
                </Button>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Observation</Form.Label>
                <Form.Control
                  name="observation"
                  onChange={handleAddClientAppareilsChange}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Row>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Ajouter appareil(s)
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {modalShow == true ? (
        <MessageAdd
          showModal={modalShow}
          closeModal={() => closeMessageModel()}
          data={existingDevice}
          device={sentData}
        />
      ) : null}
    </>
  );
}
