import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import STcontext from "../../../../context/STcontext";

const MessageAdd = ({ showModal, closeModal, data, device }) => {
  const { confirmedReturnDevice } = useContext(STcontext);
  const [returnedDevice, setReturnedDevice] = useState({
    modele_id: device.modele_id,
    deviceOwner: device.deviceOwner,
  });

  const typeTache = (catache) => {
    switch (catache) {
      case "installation":
        return "Installé";
      case "Reinstallation":
        return "Réinstallé";
      case "desinstalation":
        return "Désinstallé";
      case "Changement du GPS":
        return "changé";
    }
  };
  const handelConfirmedReturnDevice = (deviceReturned) => {
    const client = deviceReturned.latest_tache
      ? deviceReturned.latest_tache.clients.id
      : null;
    setReturnedDevice({ ...returnedDevice, client_id: client });
    confirmedReturnDevice(returnedDevice, deviceReturned.id);
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Appareil existant
        </Modal.Title>
      </Modal.Header>
      {data.map((deviceReturned, index) => (
        <>
          <Modal.Body key={index}>
            <p>
              Cet appareil (n°{" "}
              <strong> {deviceReturned.device.serial_number}</strong>) a déjà
              été{" "}
              <strong>
                {typeTache(
                  deviceReturned.device.latest_tache?.cataches.catache
                )}
              </strong>{" "}
              chez le client{" "}
              <strong>
                {" "}
                {deviceReturned.device.latest_tache?.clients.nomComplet}
              </strong>{" "}
              le{" "}
              <strong>
                {deviceReturned.device.latest_tache?.date_fin_realisation}
              </strong>.
            </p>
            <br />
            <p>Êtes-vous sûr de vouloir le renvoyer pour support technique ?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => handelConfirmedReturnDevice(deviceReturned.device)}
            >
              Confirmé
            </Button>
          </Modal.Footer>
        </>
      ))}
    </Modal>
  );
};

export default MessageAdd;
