import React, { useContext, useEffect, useState } from "react";
import STcontext from "../../context/STcontext";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TechnicianContext from "../../context/TechnicienContext";
import { FilterMatchMode } from "primereact/api";

const Filterage = ({ onStateChange }) => {
  const { NewTask } = useContext(STcontext);
  const { taskTechnicien } = useContext(TechnicianContext);
  const [filterTasksBy, setFilterTasksBy] = useState({
    city: "all",
    date: "toDay",
  });

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };
  const [filterValue, setFilterValue] = useState("");
  const [filterTasks, setFilterTasks] = useState([]);
  const handleFilter = async () => {
    const filterData = await (filterTasksBy.city !== "all"
      ? taskTechnicien.filter((task) => task.cityName === filterTasksBy.city)
      : taskTechnicien);
    handleFilterByDate(filterData);
  };
  useEffect(() => {
    handleFilter();
  }, [taskTechnicien, filterTasksBy, filterValue]);

  const formatStartDate = (value) => {
    return new Date(value).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

    // setFilterTasks(filtredTasks);

  const handleFilterByDate = (filterData) => {
    const date = new Date();
    const toDay = formatDate(date);
    let filterDataByDate = [];
    if (filterTasksBy.date === "toDay") {
    filterDataByDate = filterData.filter(
      (task) => formatStartDate(task.date_previsionnelle_debut) === toDay
    );
    } else if (filterTasksBy.date === "toMorrow") {
      filterDataByDate = filterData.filter(
        (task) => formatStartDate(task.date_previsionnelle_debut) > toDay
      );
    } else if (filterTasksBy.date === "yesterday") {
      filterDataByDate = filterData.filter(
        (task) => formatStartDate(task.date_previsionnelle_debut) < toDay
      );
    } else {
      filterDataByDate = filterData;
    }
    hanleSearch(filterDataByDate);
  };
  const onHandleSearch = (e) => {
    const keySearch = e.target.value.toLowerCase();
    setFilterValue(keySearch);
  };
  const hanleSearch = (filterDataByDate) => {
    if (filterValue != "") {
      const search = filterDataByDate.filter((task) =>
        task.client_name.toLowerCase().includes(filterValue)
      );
      if (search.length > 0) {
        onStateChange(search);
      }
      onStateChange(search);
      setFilterTasks(search);
    } else {
      onStateChange(filterDataByDate);
      setFilterTasks(filterDataByDate);
    }
  };

  return (
    <div>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>filter par ville</Form.Label>
          <Form.Select
            name="cities"
            onChange={(e) =>
              setFilterTasksBy({ ...filterTasksBy, city: e.target.value })
            }
          >
            <option selected disabled hidden>
              toutes les villes
            </option>
            <option disabled>select ville</option>
            <option value="all">toutes les villes</option>
            {NewTask &&
              NewTask.Cities.map((city, index) => (
                <option key={index} value={city.cityName}>
                  {city.cityName}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>filter par date</Form.Label>
          <Form.Select
            name="date"
            onChange={(e) =>
              setFilterTasksBy({ ...filterTasksBy, date: e.target.value })
            }
          >
            <option selected hidden>
              Aujourd'hui
            </option>
            <option disabled>select ville</option>
            <option value="all">Tout</option>
            <option value="toDay">Aujourd'hui</option>
            <option value="toMorrow">Demain</option>
            <option value="yesterday">Hier</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <div className="search-appareil-technicien input-group rounded">
          <input
            type="search"
            className="form-control rounded Search-input"
            placeholder="Recharcher par Nom Client"
            aria-label="Search"
            aria-describedby="search-addon"
            onChange={onHandleSearch}
          />
        </div>
      </Row>
    </div>
  );
};

export default Filterage;
