import React, { useContext, useEffect, useState } from "react";
import { BsToggle2On, BsCheckLg } from "react-icons/bs";
import AdminModel from "./Models/AdminModel";
import "./style/admin.css";
import Switch from "@mui/material/Switch";
import {
  MDBCardHeader,MDBCard,MDBCardTitle,MDBCardText,MDBCardBody,MDBCardImage,MDBRow,MDBCol, MDBListGroup,
} from "mdb-react-ui-kit";
import { ContextAdmin } from "../context/AdminContext";
import axios from "../context/axios";
import UpdateUser from "./UpdateUser";

const Utilisateurs = () => {
  //context of Admin logic check the context Admin to see the logic behind each one of them
  const {utilisateurs , getUtilisateurs, setUtilisateurs} = useContext(ContextAdmin);
//function responsible for the toggling button when the admin toggle a user
  const handleRadioToggle = async (e, id) => {
    e.preventDefault(); 
    const is_active = e.currentTarget.checked;
    utilisateurs.Utilisateurs.map(async(utilisateur) => {
      if (utilisateur.id === id) {
        utilisateur.is_active = !utilisateur.is_active;
         setUtilisateurs(
          {...utilisateurs,Utilisateurs:[...utilisateurs.Utilisateurs]}
        );
        await axios.put(`/api/utilisateurs/${id}/${is_active}`)
            getUtilisateurs();
      }
    });
  };
//get All of the users using useEffect uppon the component reloaded
  useEffect(() => {
    getUtilisateurs();
  }, []);

  return (
    
    <div>
     
      <div className="button-end">
        <AdminModel />
      </div>
      <div className="usercards">
        {utilisateurs.Utilisateurs.map((utilisateur) => (
          <MDBCard key={utilisateur.id} className="usercard">
            <MDBRow className="g-0" style={{ maxheight:"7rem" }}>
              <MDBCardHeader className="headercard">
                    <MDBCardTitle className="data-user">
                        {utilisateur.name}
                    </MDBCardTitle> 
           <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
            <Switch
                  id="toggle-check"
                  type="checkbox"
                  variant="outline-primary"
                  checked={utilisateur.is_active}
                  value=""
                  onChange={(e) => handleRadioToggle(e, utilisateur.id)}>
                  <BsCheckLg />
                </Switch>  
                <div style={{ display:'flex' , justifyContent:'end' , padding:'10px 10px' }}>
            <UpdateUser data={utilisateur}/>
            </div>
           </div>
                    
                         
                </MDBCardHeader>
 
            
              <MDBCol md="12">
                <MDBCardBody className="card-body">
                    <MDBCardBody className="card-info">
                        <MDBCardText className="cardTextLeft">Email:</MDBCardText>
                        <MDBCardText className="cardTextInfo">{utilisateur.email}</MDBCardText>
                    </MDBCardBody>

                    <MDBCardBody className="card-info">
                        <MDBCardText className="cardTextLeft">Telephone:</MDBCardText>
                        <MDBCardText className="cardTextInfo">{utilisateur.telephone}</MDBCardText>
                    </MDBCardBody>

                    <MDBCardBody className="card-info">
                        <MDBCardText className="cardTextLeft">Post:</MDBCardText>
                        <MDBCardText className="cardTextInfo">{utilisateur.nameProfil}</MDBCardText>
                    </MDBCardBody>

                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
          
        ))}

      </div>
    </div>
  );
};

export default Utilisateurs;