import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./axios";
import { useIdleTimer } from "react-idle-timer";
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [Users, setUsers] = useState(null);
  const [token, _setToken] = useState(localStorage.getItem("access_token"));
  const [loggedIn, setLoggedIn] = useState(false);
  const [cookieUder, _setCookieUser] = useState(null);

  const [formValue, setformValue] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValue({ ...formValue, [name]: value });
  };

  // const [error, setError] = useState([]);
  const navigate = useNavigate();

  const setCookie = (userInfo, userInfoValues) => {
    const nowDate = new Date();
    nowDate.setTime(nowDate.getTime() + 24 * 60 * 60 * 1000);
    let expires = "expires=" + nowDate.toUTCString();
    const dataString = JSON.stringify(userInfoValues);
    document.cookie =
      userInfo +
      "=" +
      encodeURIComponent(dataString) +
      ";" +
      expires +
      ";path=/";
  };

  const getCookie = (userInfo) => {
    let user = userInfo + "=";
    let decodeCokkie = decodeURIComponent(document.cookie);
    let cookies = decodeCokkie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i];
      while (c.charAt(0) == "") {
        c = c.substring(1);
      }
      if (c.indexOf(user) == 0) {
        const dataStringReturned = c.substring(user.length, c.length);

        return JSON.parse(decodeURIComponent(dataStringReturned));
      }
    }
    return null;
  };

  const [USE, setUSE] = useState({});

  const getUser = async () => {
    const { data } = await axios.get("/api/user");
  };

  const checkUserLoggedIn = async () => {
    await axios.get("api/checkLoggedIn").then((response) => {
      setLoggedIn(response.data.loggedIn);
      if (response.data.loggedIn == true) {
        redirectUser();
      } else {
        setToken(null);
      }
    });
  };

  const [error, setError] = useState();

  const handleLogin = async (e) => {
    e.preventDefault();
    await axios.get("/sanctum/csrf-cookie");
    await axios.post("/login", formValue).then(
      (res) => {
        setUSE(res.data.user);
        setCookie("userInfo", res.data.user);
        redirectUser(res.data.user);
        setToken(res.data.token);
      },
      (reason) => {
        console.error(reason); // Error!
        setError("Invalid Username or Password");
      }
    );
    const { data } = await axios.get("/api/user");
  };

  const redirectUser = (user) => {
    if (getCookie("userInfo") != null) {
      var userInformation = getCookie("userInfo");
      setUSE(userInformation);
    } else {
      var userInformation = user;
      console.log("userFrom state");
      console.log(USE);
      console.log(userInformation);
    }

    if (userInformation && userInformation.profil_id == 5) {
      navigate("/Utilisateurs");
    } else if (userInformation && userInformation.profil_id == 4) {
      navigate("/commercial/tâches");
    } else if (userInformation && userInformation.profil_id == 1) {
      navigate("/technicien/tâches");
    } else if (
      userInformation &&
      (userInformation.profil_id == 2 ||
        userInformation.profil_id == 3 ||
        userInformation.profil_id == 28)
    ) {
      navigate("/Dashboard");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (token && USE != null) {
      redirectUser();
    }
  }, [token]);

  const setToken = async (token) => {
    _setToken(token);
    if (token) {
      getCookie("userInfo");
      const setTokenToLocalStorage = new Promise(function (resolve) {
        setTimeout(function () {
          resolve(localStorage.setItem("access_token", token));
        }, 400);
      });
      await setTokenToLocalStorage;
    } else {
      localStorage.removeItem("access_token");
      return navigate("/");
    }
  };

  setTimeout(() => {
    _setToken(null);
    localStorage.removeItem("access_token");
  }, 43200000);

  const handleOnIdle = (event) => {
    _setToken(null);
    localStorage.removeItem("access_token");
  };

  const handleOnAction = (event) => {};

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
  });

  const handleLogOut = async () => {
    await axios.post("/logout").then(() => {
      setToken(null);
      setUSE({});
      navigate("/");
    });
  };

  var isRefreshing = false;

  window.onbeforeunload = () => {
    isRefreshing = true;
  };

  const [notification, setNotification] = useState({
    message: "Nouvelle Tache Pour",
    TechnicianName: "",
    status: "",
  });

  const [notificationCompta, setNotificationCompta] = useState({
    status: "",
    message: [],
  });

  const [counter, setCounter] = useState(0);
  const [isLogicDone, setIsLogicDone] = useState(false);

  const getCOmptaAppareil = async () => {
    const res = await axios.get("/api/comptable/allAppareils");
    if (res.data.NumberGps < 10) {
      setCounter((counter) => counter + 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, "GPS Almost Done"],
      }));
      setIsLogicDone(!isLogicDone);
    }
    if (res.data.NumberSIM < 10) {
      setCounter((counter) => counter + 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, "SIM Almost Done"],
      }));
      setIsLogicDone(!isLogicDone);
    }
    if (res.data.NumberAccessory < 10) {
      setCounter((counter) => counter + 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, "ACC Almost Done"],
      }));
      setIsLogicDone(!isLogicDone);
    }
    if (res.data.NumberGps > 10) {
      setCounter((counter) => counter - 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, ""],
      }));
      setIsLogicDone(!isLogicDone);
    }
    if (res.data.NumberSIM > 10) {
      setCounter((counter) => counter - 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, ""],
      }));
      setIsLogicDone(!isLogicDone);
    }
    if (res.data.NumberAccessory > 10) {
      setCounter((counter) => counter - 1);
      setNotificationCompta((prevState) => ({
        ...prevState,
        message: [...prevState.message, ""],
      }));
      setIsLogicDone(!isLogicDone);
    }
  };

  const updatedTheStatus = () => {
    setNotificationCompta((prevState) => ({
      ...prevState,
      status: counter,
    }));
  };

  return (
    <AuthContext.Provider
      value={{
        Users,
        handleLogin,
        handleChange,
        // csrf,
        error,
        formValue,
        USE,
        handleLogOut,
        getUser,
        setNotification,
        notification,
        setNotificationCompta,
        updatedTheStatus,
        getCOmptaAppareil,
        isLogicDone,
        setCounter,
        notificationCompta,
        token,
        handleOnIdle,
        checkUserLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
