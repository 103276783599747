import React, { createContext, useState } from "react";
import axios from "./axios";

export const ContextAdmin = createContext();

const AdminContext = ({ children }) => {
  const csrf = () => axios.get("/sanctum/csrf-cookie");
  const [utilisateurs, setUtilisateurs] = useState({
    Utilisateurs: [],
    Profils: [],
  });
  const [addUtilisateurs, setaddUtilisateurs] = useState([]);
  const [addProfil, setaddProfil] = useState({
    nameProfil: "",
    namePermission: [],
  });
  const [Profils, setProfils] = useState({
    AllProfils: [
      {
        id: "",
        namProfil: "",
        permissions: [{ id: "", namePermission: "" }],
      },
    ],
    AllPermission: [],
  });

  //get utilisateurs function

  const getUtilisateurs = async () => {
    const response = await axios.get("/api/utilisateurs");
    setUtilisateurs(response.data);
  };

  const handleAdd = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setaddUtilisateurs({ ...addUtilisateurs, [name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    await csrf();
    await axios.post("/dashboard", addUtilisateurs);
    getUtilisateurs();
  };

  // Profils functions

  const getProfils = async () => {
    const { data } = await axios.get("/api/profils");
    if (data.Laravel === "9.51.0") {
      setProfils({ ...Profils });
    } else {
      setProfils(data);
    }
  };

  const handelAddProfil = (e) => {
    const { name, value, checked } = e.target;
    const { namePermission, nameProfil } = addProfil;
    if (checked) {
      setaddProfil({
        ...addProfil,
        namePermission: [
          ...namePermission,
          [name] == "namePermission" ? value : "X",
        ],
        nameProfil: [name] == "nameProfil" ? value : nameProfil,
      });
    } else if (checked == false) {
      setaddProfil({
        ...addProfil,
        namePermission: namePermission.filter((e) => e !== value),
        nameProfil: [name] == "nameProfil" ? value : nameProfil,
      });
    }
  };

  const submitFormProfil = async (e) => {
    e.preventDefault();
    const data = await axios.post("/api/profils", addProfil);
    await getProfils();
    // window.location.reload(false);
  };

  const [UpdateProfil, setUpdateProfil] = useState({
    newNomComplet: "",
  });

  const handleUpdateProfil = (e) => {
    const { value, name } = e.target;
    setUpdateProfil({ ...UpdateProfil, [name]: value });
  };

  const updateProfilSubmit = (e) => {
    e.preventDefault();
    console.log(UpdateProfil);
  };
  return (
    <ContextAdmin.Provider
      value={{
        utilisateurs,
        setUtilisateurs,
        getUtilisateurs,
        handleAdd,
        submitForm,
        Profils,
        getProfils,
        handelAddProfil,
        addProfil,
        addUtilisateurs,
        submitFormProfil,
        csrf,
        handleUpdateProfil,
        updateProfilSubmit,
      }}
    >
      {children}
    </ContextAdmin.Provider>
  );
};

export default AdminContext;
