import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../style/profilModel.css";
import { ContextAdmin } from "../../context/AdminContext";
import { useNavigate } from "react-router-dom";

export default function AdminModel() {
  //admin Context we import the utilisateur state , and other functions used in the logic within this component
  const { utilisateurs, getUtilisateurs, handleAdd, submitForm } =
    useContext(ContextAdmin);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navTo = useNavigate();

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Nouveau utilisateur
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nouveau utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate method="POST" onSubmit={submitForm}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>Nom complet : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="nomComplet"
                  placeholder="Nom complet"
                  onChange={handleAdd}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>Email : </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="example@example.com"
                  name="email"
                  onChange={handleAdd}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom03">
                <Form.Label>Télephone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="0505050505"
                  name="telephone"
                  required
                  onChange={handleAdd}
                />
         
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>Profil</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="nameProfil"
                  onChange={handleAdd}
                >
                  <option selected hidden >Veuillez choisir un profil</option>
                  <option disabled>Veuillez choisir un profil</option>
                  {utilisateurs.Profils.map((profil) => (
                    <option key={profil.id} value={profil.id}>
                      {profil.nameProfil}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>Mot de passe : </Form.Label>
                <Form.Control
                  type="text"
                  name="mot_de_passe"
                  placeholder="mot de passe "
                  onChange={handleAdd}
                />
              </Form.Group>
            </Row>
            <Modal.Footer>
              <Button type="submit" variant="primary" onClick={handleClose}>
                Envoyer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}