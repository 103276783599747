import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DetailsTask = ({ task, endroit }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deviceWith = () => {
    switch(endroit.id){
      case 5:
        return "Client"
      default:
        return "D3 HI-TECH"
    }
  };
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Tâche
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{task?.cataches?.catache}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={6} md={6}>
                Client:
              </Col>
              <Col xs={12} md={6}>
                {task?.clients?.nomComplet}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Date Fin Réalisation:
              </Col>
              <Col xs={12} md={6}>
                {task?.date_fin_realisation}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={6} md={6}>
                Position GPS:
              </Col>
              <Col xs={12} md={6}>
                {task.gps_principale === 0
                  ? "1er/ première"
                  : task.gps_principale === 1
                  ? "2eme/ deuxième"
                  : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Propriétaire GPS:
              </Col>
              <Col xs={12} md={6}>
                {task.proprietaire === 0
                  ? "D3 HI-TECH"
                  : task.proprietaire === 1
                  ? "Client"
                  : "--"}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6} md={6}>
                Appareil chez:
              </Col>
              <Col xs={12} md={6}>
                {deviceWith()}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetailsTask;
