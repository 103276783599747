import React, { useContext, useEffect, useState } from "react";
import "../../App.css";
import "./style/sideBar.css";
import logo from "../../logo.png";
import LOGO from "../../logo2.png";

import { HiClipboardList } from "react-icons/hi";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";

import { NavLink } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const Sidebare = () => {
  const [Sidebar, setSidebar] = useState(false);
  const SubSidebar = async (e) => {
    e.preventDefault();
    setSidebar(!Sidebar);
  };

  const { USE } = useContext(AuthContext);
  const [imgSrc, setImgSrc] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1214) {
        setImgSrc(false);
      } else if (window.innerWidth <= 1214) {
        setImgSrc(true);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    };
    handleResize();
  }, []);

  const handleChange = () => {
    setImgSrc(!imgSrc);
  };
  return (
    <div className="sideBarContainer">
      <CDBSidebar
        toggled={imgSrc}
        className="sideBar"
        textColor=""
        backgroundColor="#ffffff"
      >
        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            {!imgSrc ? (
              <img className="logo1 sc-gScZFl" src={logo} />
            ) : (
              <img className="logo2 sc-gScZFl" src={LOGO} />
            )}

            {USE.profil_id != 5 && USE.profil_id != 4 && USE.profil_id != 1 ? (
              <NavLink
                exact
                to="/Dashboard"
                style={({ isActive }) =>
                  isActive
                    ? { color: "#00DEA3", fontWeight: "650" }
                    : { color: "black" }
                }
              >
                <CDBSidebarMenuItem
                  icon="home"
                  onClick={() => setSidebar(false)}
                >
                  Dashboard
                </CDBSidebarMenuItem>
              </NavLink>
            ) : (
              <></>
            )}

            {USE.profil_id === 3 ||
            USE.profil_id === 2 ||
            USE.profil_id === 28 ? (
              <NavLink
                exact
                to={
                  USE.profil_id === 2 || USE.profil_id === 3
                    ? "/SupportTechnique/tâches"
                    : "/Comptable/tâches"
                }
                style={({ isActive }) =>
                  isActive
                    ? { color: "#00DEA3", fontWeight: "650" }
                    : { color: "black" }
                }
              >
                <CDBSidebarMenuItem icon="list" onClick={SubSidebar}>
                  Tâches <i class="fa fa-caret-down" aria-hidden="true"></i>
                </CDBSidebarMenuItem>
              </NavLink>
            ) : (
              <></>
            )}

            <div
              style={{ paddingLeft: "20px", fontSize: "15px", color: "gray" }}
            >
              {Sidebar == true ? (
                <div>
                  <NavLink
                    exact
                    to={
                      USE.profil_id === 2 || USE.profil_id === 3
                        ? "/SupportTechnique/tâches/technicien"
                        : "/Comptable/tâches/technicien"
                    }
                    style={({ isActive }) =>
                      isActive
                        ? { color: "#00DEA3", fontWeight: "650" }
                        : { color: "black" }
                    }
                  >
                    <CDBSidebarMenuItem style={{ width: "100%" }}>
                      Technicien
                    </CDBSidebarMenuItem>
                  </NavLink>
                  <NavLink
                    exact
                    to={
                      USE.profil_id === 2 || USE.profil_id === 3
                        ? "/SupportTechnique/tâches/supporttechnique"
                        : "/Comptable/tâches/supporttechnique"
                    }
                    style={({ isActive }) =>
                      isActive
                        ? { color: "#00DEA3", fontWeight: "650" }
                        : { color: "black" }
                    }
                  >
                    <CDBSidebarMenuItem style={{ width: "100%" }}>
                      Support technique
                    </CDBSidebarMenuItem>
                  </NavLink>

                  <NavLink
                    exact
                    to={
                      USE.profil_id === 2 || USE.profil_id === 3
                        ? "/SupportTechnique/tâches/Commercial"
                        : "/Comptable/tâches/Commercial"
                    }
                    style={({ isActive }) =>
                      isActive
                        ? { color: "#00DEA3", fontWeight: "650" }
                        : { color: "black" }
                    }
                  >
                    <CDBSidebarMenuItem style={{ width: "100%" }}>
                      Commercial
                    </CDBSidebarMenuItem>
                  </NavLink>
                </div>
              ) : (
                <></>
              )}
            </div>

            {USE.profil_id != 5 && USE.profil_id != 4 && USE.profil_id != 1 ? (
              <NavLink
                exact
                to="/Calendrier"
                style={({ isActive }) =>
                  isActive
                    ? { color: "#00DEA3", fontWeight: "650" }
                    : { color: "black" }
                }
              >
                <CDBSidebarMenuItem icon="th" onClick={() => setSidebar(false)}>
                  Calendrier
                </CDBSidebarMenuItem>
              </NavLink>
            ) : (
              <></>
            )}

            {USE.profil_id != 5 &&
            USE.profil_id != 4 &&
            USE.profil_id != 1 &&
            USE.profil_id != 3 &&
            USE.profil_id != 28 ? (
              <NavLink
                exact
                to="/Supporttechnique/appareil"
                style={({ isActive }) =>
                  isActive
                    ? { color: "#00DEA3", fontWeight: "650" }
                    : { color: "black" }
                }
              >
                <CDBSidebarMenuItem
                  icon="shopping-bag"
                  onClick={() => setSidebar(false)}
                >
                  Appareil
                </CDBSidebarMenuItem>
              </NavLink>
            ) : (
              <></>
            )}

            {/* Gerant */}

            {USE.profil_id == 3 ? (
              <NavLink
                exact
                to="/Gerant/appareils"
                style={({ isActive }) =>
                  isActive
                    ? { color: "#00DEA3", fontWeight: "650" }
                    : { color: "black" }
                }
              >
                <CDBSidebarMenuItem
                  icon="shopping-bag"
                  onClick={() => setSidebar(false)}
                >
                  Appareil
                </CDBSidebarMenuItem>
              </NavLink>
            ) : (
              <></>
            )}

            {/* Comptable */}

            {USE.profil_id == 28 ? (
              <NavLink
                exact
                to="/comptable/appareil"
                style={({ isActive }) =>
                  isActive
                    ? { color: "#00DEA3", fontWeight: "650" }
                    : { color: "black" }
                }
              >
                <CDBSidebarMenuItem
                  icon="shopping-bag"
                  onClick={() => setSidebar(false)}
                >
                  Appareil
                </CDBSidebarMenuItem>
              </NavLink>
            ) : (
              <></>
            )}

            {/* Gerant */}

            {USE.profil_id != 5 && USE.profil_id != 4 && USE.profil_id != 1 ? (
              <NavLink
                exact
                to="/supporttechnique/appareilretournées/GPS"
                style={({ isActive }) =>
                  isActive
                    ? { color: "#00DEA3", fontWeight: "650" }
                    : { color: "black" }
                }
              >
                <CDBSidebarMenuItem
                  icon="undo"
                  onClick={() => setSidebar(false)}
                >
                  Appareils retournées
                </CDBSidebarMenuItem>
              </NavLink>
            ) : (
              <></>
            )}

            {USE.profil_id != 5 &&
            USE.profil_id != 4 &&
            USE.profil_id != 1 &&
            USE.profil_id != 3 &&
            USE.profil_id != 28 ? (
              <NavLink
                exact
                to="/supporttechnique/tachesaconfirmer"
                style={({ isActive }) =>
                  isActive
                    ? { color: "#00DEA3", fontWeight: "650" }
                    : { color: "black" }
                }
              >
                <CDBSidebarMenuItem
                  icon="clipboard"
                  onClick={() => setSidebar(false)}
                >
                  Tâches a confirmer
                </CDBSidebarMenuItem>
              </NavLink>
            ) : (
              <></>
            )}

            {/* end st */}

            {/* Geerant */}

            {USE.profil_id != 5 &&
            USE.profil_id != 4 &&
            USE.profil_id != 1 &&
            USE.profil_id != 2 ? (
              <NavLink
                exact
                to="/Gerant/tâches-à-confirmer"
                style={({ isActive }) =>
                  isActive
                    ? { color: "#00DEA3", fontWeight: "650" }
                    : { color: "black" }
                }
              >
                <CDBSidebarMenuItem
                  icon="clipboard"
                  onClick={() => setSidebar(false)}
                >
                  Tâches à confirmer
                </CDBSidebarMenuItem>
              </NavLink>
            ) : (
              <></>
            )}

            {/* Technicien */}

            {USE.profil_id === 1 ? (
              <>
                <NavLink
                  exact
                  to="/technicien/tâches"
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#00DEA3", fontWeight: "650" }
                      : { color: "black" }
                  }
                >
                  <CDBSidebarMenuItem icon="list">Tâches</CDBSidebarMenuItem>
                </NavLink>

                <NavLink
                  exact
                  to="/technicien/appareil"
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#00DEA3", fontWeight: "650" }
                      : { color: "black" }
                  }
                >
                  <CDBSidebarMenuItem
                    icon="shopping-bag"
                    onClick={() => setSidebar(false)}
                  >
                    Appareil
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/technicien/Appareils-désinstallés"
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#00DEA3", fontWeight: "650" }
                      : { color: "black" }
                  }
                >
                  <CDBSidebarMenuItem
                    icon="undo"
                    onClick={() => setSidebar(false)}
                  >
                    Appareils désinstallés
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/technicien/taches/confirmer"
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#00DEA3", fontWeight: "650" }
                      : { color: "black" }
                  }
                >
                  <CDBSidebarMenuItem
                    icon="pen"
                    onClick={() => setSidebar(false)}
                  >
                    Tâches a confirmer
                  </CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : (
              <></>
            )}

            {/* end technicien  */}

            {/* Comptable */}

            {USE.profil_id == 28 ? (
              <>
                <NavLink
                  exact
                  to="comptable/paramétrage"
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#00DEA3", fontWeight: "650" }
                      : { color: "black" }
                  }
                >
                  <CDBSidebarMenuItem
                    icon="cog"
                    onClick={() => setSidebar(false)}
                  >
                    Paramétrage
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="comptable/Tout Cients"
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#00DEA3", fontWeight: "650" }
                      : { color: "black" }
                  }
                >
                  <CDBSidebarMenuItem
                    icon="user"
                    onClick={() => setSidebar(false)}
                  >
                    clients
                  </CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : (
              <></>
            )}

            {/* Admin  */}

            {USE.profil_id == 5 && USE.profil_id != 4 ? (
              <div>
                <NavLink
                  exact
                  to="/utilisateurs"
                  activeClassName="activeClicked"
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#00DEA3", fontWeight: "650" }
                      : { color: "black" }
                  }
                >
                  <CDBSidebarMenuItem icon="users">
                    Utilisateurs
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink
                  exact
                  to="/profil"
                  activeClassName="activeClicked"
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#00DEA3", fontWeight: "650" }
                      : { color: "black" }
                  }
                >
                  <CDBSidebarMenuItem icon="user">Profils</CDBSidebarMenuItem>
                </NavLink>
              </div>
            ) : (
              <></>
            )}

            {/* end Admin  */}

            {/* Commercial component */}

            {USE.profil_id == 4 ? (
              <>
                <NavLink
                  exact
                  to="/commercial/tâches"
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#00DEA3", fontWeight: "650" }
                      : { color: "black" }
                  }
                >
                  <CDBSidebarMenuItem icon="list">Tâches</CDBSidebarMenuItem>
                </NavLink>

                <NavLink
                  exact
                  to="commercial/Tout Cients"
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#00DEA3", fontWeight: "650" }
                      : { color: "black" }
                  }
                >
                  <CDBSidebarMenuItem
                    icon="user"
                    onClick={() => setSidebar(false)}
                  >
                    clients
                  </CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : (
              <></>
            )}

            {/* end commercial component */}
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarHeader
          prefix={
            <i onClick={handleChange} className="fa fa-bars fa-large"></i>
          }
        >
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: "inherit" }}
          ></a>
        </CDBSidebarHeader>
      </CDBSidebar>
    </div>
  );
};

export default Sidebare;
