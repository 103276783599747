import React from "react";
import "./style/dashboard.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

export default function AppCards({ data }) {
  return (
    <MDBRow className="cardRow">
      <MDBCol>
        <MDBCard className="cardStats">
          <MDBCardBody>
            <MDBCardTitle>Nbr GPS installé </MDBCardTitle>
            <MDBCardText>
              {data && data.numberGPS != null ? data.numberGPS : "en cours"}
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className="cardStats">
          <MDBCardBody>
            <MDBCardTitle>Modéle plus installée</MDBCardTitle>
            <MDBCardText>
              {data && data.mostmodel && data.mostmodel.mostGPSInstalled > 0
                ? data.mostmodel.nameModele
                : data.mostmodel == null
                ? "Aucun modèle disponible"
                : "en cours"}
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className="cardStats">
          <MDBCardBody>
            <MDBCardTitle>Nbr SIM installée</MDBCardTitle>
            <MDBCardText>
              {data && data.numberSIM >= 0 ? data.numberSIM : "en cours"}
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className="cardStats">
          <MDBCardBody>
            <MDBCardTitle>Acc plus installée</MDBCardTitle>
            <MDBCardText>
              {data &&
              data.ACCESSORY &&
              data.ACCESSORY.mostAccessoryInstalled > 0
                ? data.ACCESSORY.nameModele
                : data.ACCESSORY == null
                ? "pas accessoire  installé"
                : "en cours"}
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}
