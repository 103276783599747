import React, { memo, useCallback, useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "react-bootstrap/Button";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import STcontext from "../../../../context/STcontext";
import DetailsTask from "./DetailsTask";

const LogDevices = () => {
  const { getLogOfDevice, logDevices } = useContext(STcontext);

  const { device, appareil } = useParams();  
  const location = useLocation();
  const deviceId = location.state.idDevice;

  const handleGetLogOfDevice=useCallback(()=>{
    getLogOfDevice(deviceId)
  }, [deviceId])
  useEffect(() => {
    handleGetLogOfDevice();
  }, [handleGetLogOfDevice]);  
  
  const formatDateBody = (rowData) => {
    return formatDate(rowData?.pivot.created_at);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString("en-GB");
  };
  const acteurBody = (rowData) => {
    return rowData.pivot.acteur.name;
  };

  const actionBody = (rowData) => {
    
    switch (rowData.id) {
      case 3:
        switch (true) {
          default:
            return "Envoyé à";
          case rowData.pivot.acteur.profil_id == 1 &&
            rowData.pivot.recipient.profil_id == 1:
            return "Reste chez";
        }
      case 4:
        switch (true) {
          case rowData.pivot.acteur.profil_id == 28 &&
            rowData.pivot.recipient.profil_id != 28:
            return "Envoyé à";
          case rowData.pivot.acteur.profil_id == 2 &&
            rowData.pivot.recipient.profil_id == 2:
            return "Ajouté";
        }
      case 5:
        return logDevices.all_taches[logDevices.all_taches.length -1].cataches.catache;
      case 6:
        switch (true) {
          case rowData.pivot.acteur.profil_id == 28 &&
            rowData.pivot.recipient?.profil_id == 28:
            return "Récupération";
          case rowData.pivot.acteur.profil_id == 28 && 
            rowData.pivot.recipient == null:
            return "Ajouté"; 
        }
      case 7:
        return "supprimé";
    }
  };
  const consigneeBody = (rowData) => {
    return rowData.pivot.recipient?.name;
  };

  const formatFilterDate = (date) => {
    return new Date(date).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const spreadArrayFun = (value) => {
    const task = {
      ...value,
      proprietaire: logDevices.proprietaire,
      gps_principale: logDevices.gps_principale,
    };
    return task;
  };
  

  const latestTask = (rowData) => {
    switch (true) {
      case rowData.id == 5 && appareil == "GPS":
        const filterTaskByDate = logDevices.all_taches?logDevices.all_taches.filter(
          (task) =>
            formatFilterDate(task.date_fin_realisation) ==
            formatFilterDate(rowData?.pivot.created_at)
        ):[];
        const speardArray = spreadArrayFun(...filterTaskByDate);
        return <DetailsTask task={speardArray} endroit = {logDevices.endroits[0]} />;
    }
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/Gerant/appareils", {
      state: {
        fromProfile: true, // Example of state you might want to pass
        params: appareil,
      },
    }); // Go back to the previous route in history
  };
  return (
    <div
      style={{
        width: "90%",
        padding: "2rem",
        marginTop: "1rem",
        marginLeft: "50px",
        borderRadius: "2rem",
      }}
    >
      <Button
        variant="secondary"
        onClick={() => goBack()}
        style={{ marginLeft: "1rem" }}
      >
        <ArrowBackRoundedIcon />
      </Button>{" "}
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          padding: "2rem",
          marginTop: "1rem",
          borderRadius: "2rem",
        }}
      >
        <div style={{ padding: "0rem 2rem 1rem 2rem " }}>
          <div>
            <h5>
              Appareil &nbsp; <span class="vl"></span> &nbsp;
              <h4 style={{ fontSize: "15px", display: "inline-block" }}>
                {device}
              </h4>
            </h5>
          </div>
        </div>

        <DataTable
          value={logDevices.endroits}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          // dataKey="id"
          emptyMessage="Aucun appareil trouvé."
        >
          <Column
            header="#"
            headerStyle={{ width: "3rem" }}
            body={(data, options) => options.rowIndex + 1}
            bodyStyle={{ fontWeight: "bold" }}
          ></Column>
          <Column
            header="Date"
            field="created_at"
            body={formatDateBody || null}
            style={{ minWidth: "12rem", height: "4rem" }}
          />
          <Column
            header="Acteur"
            field="acteur"
            body={acteurBody || null}
            filterPlaceholder="Search by name"
            style={{ minWidth: "12rem", height: "4rem" }}
          />
          <Column
            header="Action"
            field="logDevices.endroits.acteur"
            body={actionBody || null}
            showFilterMenu={false}
            style={{ minWidth: "14rem", height: "4rem" }}
          />

          <Column
            header="Destinataire"
            body={consigneeBody || null}
            showFilterMenu={false}
            style={{ minWidth: "12rem", height: "4rem" }}
          />
          {
            appareil == "GPS"?
            <Column
            header="Dernière tâche"
            body={latestTask || null}
            showFilterMenu={false}
            style={{ minWidth: "12rem", height: "4rem" }}
          />
          :null
          }
          
        </DataTable>
      </div>
    </div>
  );
};
export default memo(LogDevices)
