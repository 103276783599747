import React, { useContext, useEffect } from "react";
import "./style/admin.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import ProfilModel from "./Models/ProfilModel";
import { ContextAdmin } from "../context/AdminContext";

export default function Profil() {
  //get all the profils of each users context
  const { Profils, getProfils } = useContext(ContextAdmin);
//useEffect hook executing upon refresh of the component or any change made within it ,and execute inside of the function that get you all profiles
  useEffect(() => {
    getProfils();
  }, []);

  return (
    <div>
      <div className="button-end">
        <ProfilModel />
      </div>

      <MDBContainer className="porfils pt-4" style={{ maxWidth: "1929" }}>
        <MDBAccordion alwaysOpen initialActive={1}>
          {Profils.AllProfils.map((profil) => (
            <MDBAccordionItem
              collapseId={1}
              headerTitle={profil.nameProfil}
              key={profil.id}
            >
              <ul className="permission">
                {profil.permissions.length > 0
                  ? profil.permissions.map((permission) => (
                      <li key={permission.id}>{permission.namePermission}</li>
                    ))
                  : <span>no permission</span>}
              </ul>
            </MDBAccordionItem>
          ))}

          
        </MDBAccordion>
      </MDBContainer>
    </div>
  );
}
