import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../style/profilModel.css";
import { useContext } from 'react';
import { ContextAdmin } from '../../context/AdminContext';


   

export default function ProfilModel() {
//admin context getting the profils state from the context and some add function to be used within this component 
  const {handelAddProfil,Profils,submitFormProfil, addProfil} = useContext(ContextAdmin);
  

//logic related to the modals
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <div>
      <Button className='nouveauProfilButton' onClick={handleShow}>
          Nouveau profil
      </Button>

      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nouveau Profil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
        <Form noValidate method='POST' onSubmit={submitFormProfil}>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>Nom Profil : </Form.Label>
          <Form.Control
            required
            type="text"
            name='nameProfil'
            onChange={handelAddProfil}
            placeholder="Nom Profil"

          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Permission : </Form.Label>
          {Profils.AllPermission.map((permission)=>(
             <Form.Check key={permission.id} 
            type="checkbox"
            label={permission.namePermission}
            value={permission.id}
            name='namePermission'
            onClick={handelAddProfil}
          />
          ))}

        </Form.Group>
      </Row>
      <Modal.Footer>
    
          <Button type='submit' className='Modelbutton' variant="primary" onClick={handleClose}>
            Envoyer
          </Button>
        </Modal.Footer>

      </Form>
        </Modal.Body>

      </Modal>

    </div>
  )
}
